import React, { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { UserProfileSteps } from './userProfileConfig';
import { Redirect, useHistory } from "react-router-dom";
import { AuthCredentials, doRegisterAsync } from "../auth";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Email } from "@material-ui/icons";
import { PasswordChange, updateUserProfile } from "../auth/authAPI";
import { updateUserProfileAsync } from "../auth/authSlice";
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

export interface UserProfilePageProps {
    action: "register" | "userProfile";
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function initStepValuesArray() {
    let blah = [];
    blah[0] = "blah";
    blah[10] = "blah blah";
}
// TODO: Initial values come from redux when action === userProfile
export default function UserProfilePage(props: UserProfilePageProps) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [submissionError, setSubmissionError] = React.useState(false);
    const [submissionSuccess, setSubmissionSuccess] = React.useState(false);
    const [stepValues, setStepValues] = React.useState({});
    const [successSnackbarUserClosed, setSuccessSnackbarUserClosed] = React.useState(false);
    const [authErrorSnackbarUserClosed, setAuthErrorSnackbarUserClosed] = React.useState(false);
    const [submissionErrorSnackbarUserClosed, setSubmissionErrorSnackbarUserClosed] = React.useState(false);

    const dispatch = useAppDispatch();
    const authUser = useAppSelector(state => state.auth);
    const authError = authUser.error;
    const history = useHistory();

    const handleNext = (values: any) => {
        const updatedStepValues = {
            ...stepValues,
            ...values
        };
        setStepValues(updatedStepValues);

        if (activeStep === UserProfileSteps.length - 1) {
            let userProfile = { ...updatedStepValues };
            delete userProfile.email;

            // TODO: Below is a temporary implementation to be fixed during admin phase
            // Are you a CSMS Section EPOC? if yes, force "no" to "Are you an EPOC"
            // CSMS Section EPOC field: 29e85428-6d03-4d9f-b07e-8255fc462232
            //      - Yes - key: 1
            // Are you an EPOC field: dc024ff8-7046-422f-8628-fcd7b247a72e
            //      - No - key: 2
            if (userProfile["29e85428-6d03-4d9f-b07e-8255fc462232"] === "1") {
                userProfile["dc024ff8-7046-422f-8628-fcd7b247a72e"] = "2";
            }

            //finished, submit
            setSuccessSnackbarUserClosed(false);
            setSubmissionErrorSnackbarUserClosed(false);
            setAuthErrorSnackbarUserClosed(false);

            if (props.action === "register") {
                delete userProfile.password;
                delete userProfile.confirmPassword;
                const credentials: AuthCredentials = {
                    email: updatedStepValues.email,
                    password: updatedStepValues.password,
                    confirmPassword: updatedStepValues.confirmPassword,
                    redirectTo: "/"
                };
                const registerResult = dispatch(doRegisterAsync({ credentials, userProfile })).then((value) => {
                    // history.push("/");

                    // if (success) {
                    //     history.push("/");
                    // } else {
                    //     setSubmissionError(true);
                    // }                    
                }, (rejected: any) => setSubmissionError(true));
            } else {
                // update user profile
                delete userProfile.oldPassword;
                delete userProfile.newPassword;
                delete userProfile.confirmNewPassword;

                const newPassword: PasswordChange = {
                    //used for user profile password updates
                    oldPassword: updatedStepValues.oldPassword,
                    newPassword: updatedStepValues.newPassword,
                    confirmNewPassword: updatedStepValues.confirmNewPassword
                };
                const formEmail = updatedStepValues.email as string;
                const authEmail = authUser.user?.email;
                const newEmail = formEmail === authEmail ? undefined : formEmail;

                dispatch(updateUserProfileAsync({ userProfile, newPassword, newEmail })).then((value) => { setSubmissionSuccess(true); }, (rejected: any) => setSubmissionError(true));
            }
            //
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleGoPreviousPage = () => {
        history.goBack();
    };
    return (
        <div>
            {/* {submissionError && <Typography color="error">An unknown error was encountered. Please try again later.</Typography>}
            {authError && <Typography color="error">{<>{authError.split("\n").map((e, i) => <span key={i}>{e.trim()}<br /></span>)}</>}</Typography>} */}
            <Snackbar
                open={submissionSuccess && !authError && !successSnackbarUserClosed}
                onClose={() => setSuccessSnackbarUserClosed(true)}
            >
                <>
                    <Alert severity="success" onClose={() => setSuccessSnackbarUserClosed(true)}>User profile updated successfully!</Alert>

                </>
            </Snackbar>
            <Snackbar
                open={submissionError && !submissionErrorSnackbarUserClosed}
                onClose={() => setSubmissionErrorSnackbarUserClosed(true)}
            >
                <>
                    <Alert severity="error" onClose={() => setSubmissionErrorSnackbarUserClosed(true)}>An unknown error was encountered. Please try again later.</Alert>

                </>
            </Snackbar>
            <Snackbar
                open={(authError !== undefined && authError !== "") && !authErrorSnackbarUserClosed}
                onClose={() => setAuthErrorSnackbarUserClosed(true)}
            >
                <>
                    <Alert severity="error" onClose={() => setAuthErrorSnackbarUserClosed(true)}><>{authError?.split("\n").map((e, i) => <span key={i}>{e.trim()}<br /></span>)}</></Alert>

                </>
            </Snackbar>
            <Stepper activeStep={activeStep} orientation="vertical">
                {UserProfileSteps.map((step, idx) => {

                    return (
                        <Step key={step.name}>                            
                            <StepLabel>
                                {step.name}
                                {(idx === 0 && authError) ? <Typography color="error">{<>{authError.split("\n").map((e, i) => <span key={i}>{e.trim()}<br /></span>)}</>}</Typography> : null}
                            </StepLabel>
                            
                            <StepContent>
                                <Typography>{step.description}</Typography>
                                {step.component({
                                    onNext: {
                                        label: activeStep === UserProfileSteps.length - 1 ? "Finish" : "Next",
                                        callback: handleNext
                                    },
                                    onPrev: {
                                        label: "Back",
                                        callback: activeStep === 0 ? handleGoPreviousPage : handleBack
                                    },
                                    action: props.action
                                })}
                                {(idx === 2 && submissionError) ? <Typography color="error">An unknown error was encountered. Please try again later.</Typography> : null}
                                
                            </StepContent>
                        </Step>
                    );
                })}
            </Stepper>
            {!authError && props.action === "register" && authUser.isAuthenticated && <Redirect to="/" />}
        </div>
    );
}
