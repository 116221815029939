import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { AUTH_URL } from "../../app/runtimeConstants";
import { useSnackbar } from "notistack";

interface ForgottenPasswordProps {
    onClose: () => void;
    open: boolean;
    initialEmail: string;
}

function ForgottenPassword(props: ForgottenPasswordProps) {
    const { onClose, initialEmail, open } = props;
    const [email, setEmail] = React.useState(initialEmail);
    const { enqueueSnackbar } = useSnackbar();

    const handleCancel = () => {
        onClose();
    }

    const handleSubmit = (e: React.MouseEvent) => { 
        onClose();
        
        let formData = new FormData();
        formData.append('Email', email);


        fetch(`${AUTH_URL}/Identity/Account/ForgotPassword`, {
            method: "post",
            body: formData
        }).then(success => {
            enqueueSnackbar("Please check your email to reset your password.", { variant: "info" });
        }).catch(rejected => {
            enqueueSnackbar("An error occured while processing your Forgotten Password Request. Please try again later or contact help@ngor-lms.com.", { variant: "error" });
        });
    };
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => { setEmail(event.target.value)};


    return (
        <Dialog onClose={handleCancel} open={open}>
            <DialogTitle>Forgotten Password Recovery</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To receive a link to reset your password, please enter your account email address below and click submit.
                </DialogContentText>
                <TextField
                    autoFocus
                    fullWidth                    
                    margin="dense"
                    label="Email"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                />
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleCancel} color="primary">
                    Cancel
          </Button>
                <Button name="submit" onClick={handleSubmit} color="primary">
                    Submit
          </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ForgottenPassword;