import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { userManager } from "../../../features/auth";


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        '& li': {
            marginBottom: theme.spacing(1)
        }
    }
}));

function Home() {
    const dispatch = useAppDispatch();
    const user = useAppSelector(state => state.auth.user);
    const classes = useStyles();
    return (
        <Grid container spacing={5} className={classes.root}>
            <Grid item xs={12} sm={6} md={8}>
                <h4>{`Welcome ${user ? user.email : ""}`}</h4>
                <p>This application provides you with access to various training courses and reference materials.</p>
                <p>The following pages are available by clicking the links above:</p>
                <ul>
                    <li>Home - this page</li>
                    <li>User Profile - update your user information</li>
                    <li>Courses - view your training requirements and access courses</li>
                    <li>Downloads - view various training documents</li>
                </ul>                
                <p>For assistance, please contact <a href="mailto:help@ngor-lms.com">help@ngor-lms.com</a></p>

            </Grid>
            <Grid item xs={12} sm={6} md={4} >
                                
            </Grid>
            {/* <Grid item md={12}>
                <p>Do fugiat dolor excepteur sint exercitation adipisicing. Ullamco enim duis magna cupidatat aliqua duis cupidatat id excepteur adipisicing ullamco non Lorem in. Ea deserunt non magna ex laboris reprehenderit voluptate incididunt incididunt fugiat aliquip veniam. Eu veniam velit occaecat minim. Aliqua quis veniam in nulla laboris in in elit excepteur ullamco qui. Magna eu ea amet anim excepteur nulla labore exercitation cupidatat incididunt anim dolore est veniam. Velit laborum irure reprehenderit consequat occaecat ut tempor irure.</p>
                <p>Do fugiat dolor excepteur sint exercitation adipisicing. Ullamco enim duis magna cupidatat aliqua duis cupidatat id excepteur adipisicing ullamco non Lorem in. Ea deserunt non magna ex laboris reprehenderit voluptate incididunt incididunt fugiat aliquip veniam. Eu veniam velit occaecat minim. Aliqua quis veniam in nulla laboris in in elit excepteur ullamco qui. Magna eu ea amet anim excepteur nulla labore exercitation cupidatat incididunt anim dolore est veniam. Velit laborum irure reprehenderit consequat occaecat ut tempor irure.</p>
                <p>Do fugiat dolor excepteur sint exercitation adipisicing. Ullamco enim duis magna cupidatat aliqua duis cupidatat id excepteur adipisicing ullamco non Lorem in. Ea deserunt non magna ex laboris reprehenderit voluptate incididunt incididunt fugiat aliquip veniam. Eu veniam velit occaecat minim. Aliqua quis veniam in nulla laboris in in elit excepteur ullamco qui. Magna eu ea amet anim excepteur nulla labore exercitation cupidatat incididunt anim dolore est veniam. Velit laborum irure reprehenderit consequat occaecat ut tempor irure.</p>
                <p>Do fugiat dolor excepteur sint exercitation adipisicing. Ullamco enim duis magna cupidatat aliqua duis cupidatat id excepteur adipisicing ullamco non Lorem in. Ea deserunt non magna ex laboris reprehenderit voluptate incididunt incididunt fugiat aliquip veniam. Eu veniam velit occaecat minim. Aliqua quis veniam in nulla laboris in in elit excepteur ullamco qui. Magna eu ea amet anim excepteur nulla labore exercitation cupidatat incididunt anim dolore est veniam. Velit laborum irure reprehenderit consequat occaecat ut tempor irure.</p>
                <p>Do fugiat dolor excepteur sint exercitation adipisicing. Ullamco enim duis magna cupidatat aliqua duis cupidatat id excepteur adipisicing ullamco non Lorem in. Ea deserunt non magna ex laboris reprehenderit voluptate incididunt incididunt fugiat aliquip veniam. Eu veniam velit occaecat minim. Aliqua quis veniam in nulla laboris in in elit excepteur ullamco qui. Magna eu ea amet anim excepteur nulla labore exercitation cupidatat incididunt anim dolore est veniam. Velit laborum irure reprehenderit consequat occaecat ut tempor irure.</p>
                <p>Do fugiat dolor excepteur sint exercitation adipisicing. Ullamco enim duis magna cupidatat aliqua duis cupidatat id excepteur adipisicing ullamco non Lorem in. Ea deserunt non magna ex laboris reprehenderit voluptate incididunt incididunt fugiat aliquip veniam. Eu veniam velit occaecat minim. Aliqua quis veniam in nulla laboris in in elit excepteur ullamco qui. Magna eu ea amet anim excepteur nulla labore exercitation cupidatat incididunt anim dolore est veniam. Velit laborum irure reprehenderit consequat occaecat ut tempor irure.</p>
                <p>Do fugiat dolor excepteur sint exercitation adipisicing. Ullamco enim duis magna cupidatat aliqua duis cupidatat id excepteur adipisicing ullamco non Lorem in. Ea deserunt non magna ex laboris reprehenderit voluptate incididunt incididunt fugiat aliquip veniam. Eu veniam velit occaecat minim. Aliqua quis veniam in nulla laboris in in elit excepteur ullamco qui. Magna eu ea amet anim excepteur nulla labore exercitation cupidatat incididunt anim dolore est veniam. Velit laborum irure reprehenderit consequat occaecat ut tempor irure.</p>
                <p>Do fugiat dolor excepteur sint exercitation adipisicing. Ullamco enim duis magna cupidatat aliqua duis cupidatat id excepteur adipisicing ullamco non Lorem in. Ea deserunt non magna ex laboris reprehenderit voluptate incididunt incididunt fugiat aliquip veniam. Eu veniam velit occaecat minim. Aliqua quis veniam in nulla laboris in in elit excepteur ullamco qui. Magna eu ea amet anim excepteur nulla labore exercitation cupidatat incididunt anim dolore est veniam. Velit laborum irure reprehenderit consequat occaecat ut tempor irure.</p>
                <p>Do fugiat dolor excepteur sint exercitation adipisicing. Ullamco enim duis magna cupidatat aliqua duis cupidatat id excepteur adipisicing ullamco non Lorem in. Ea deserunt non magna ex laboris reprehenderit voluptate incididunt incididunt fugiat aliquip veniam. Eu veniam velit occaecat minim. Aliqua quis veniam in nulla laboris in in elit excepteur ullamco qui. Magna eu ea amet anim excepteur nulla labore exercitation cupidatat incididunt anim dolore est veniam. Velit laborum irure reprehenderit consequat occaecat ut tempor irure.</p>
                <p>Do fugiat dolor excepteur sint exercitation adipisicing. Ullamco enim duis magna cupidatat aliqua duis cupidatat id excepteur adipisicing ullamco non Lorem in. Ea deserunt non magna ex laboris reprehenderit voluptate incididunt incididunt fugiat aliquip veniam. Eu veniam velit occaecat minim. Aliqua quis veniam in nulla laboris in in elit excepteur ullamco qui. Magna eu ea amet anim excepteur nulla labore exercitation cupidatat incididunt anim dolore est veniam. Velit laborum irure reprehenderit consequat occaecat ut tempor irure.</p>
                <p>Do fugiat dolor excepteur sint exercitation adipisicing. Ullamco enim duis magna cupidatat aliqua duis cupidatat id excepteur adipisicing ullamco non Lorem in. Ea deserunt non magna ex laboris reprehenderit voluptate incididunt incididunt fugiat aliquip veniam. Eu veniam velit occaecat minim. Aliqua quis veniam in nulla laboris in in elit excepteur ullamco qui. Magna eu ea amet anim excepteur nulla labore exercitation cupidatat incididunt anim dolore est veniam. Velit laborum irure reprehenderit consequat occaecat ut tempor irure.</p>
                <p>Do fugiat dolor excepteur sint exercitation adipisicing. Ullamco enim duis magna cupidatat aliqua duis cupidatat id excepteur adipisicing ullamco non Lorem in. Ea deserunt non magna ex laboris reprehenderit voluptate incididunt incididunt fugiat aliquip veniam. Eu veniam velit occaecat minim. Aliqua quis veniam in nulla laboris in in elit excepteur ullamco qui. Magna eu ea amet anim excepteur nulla labore exercitation cupidatat incididunt anim dolore est veniam. Velit laborum irure reprehenderit consequat occaecat ut tempor irure.</p>
            </Grid> */}
        </Grid>
    );
}

export default Home;