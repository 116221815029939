import React from 'react';
import { Box, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import jsonCourseData from "./data/data_courses.json";
import { Course } from "./data/course";
import { API_URL } from "../../app/runtimeConstants";
const courses: Course[] = (jsonCourseData.courses as Course[])
    .filter(c => c.details.kind === "InPersonEvent" && c.details.scheduledEvents.length > 0);
    // .filter(c => c.uid === "CC41734B-4B07-4F3C-AE6B-F2B01AC2B809" ||
    //     c.uid === "7ED3AE12-4AD6-463D-BD77-CEA8CA7BF635" ||
    //     c.uid === "62816578-266D-488A-B15F-1A344812F210");

interface EnrollmentData {
    [eventUid: string]: Array<
        {
            userId: string,
            status: string,
            email: string;
        }>;
}



export default function Enrollment() {
    const [data, setData] = React.useState<EnrollmentData>({});

    React.useEffect(() => {
        const promises: Promise<EnrollmentData>[] = [];
        courses.forEach(c => {
            if (c.details.kind === "InPersonEvent" && c.details.scheduledEvents.length > 0) {
                c.details.scheduledEvents.forEach(e => {
                    const courseUid = e.courseUid;
                    const eventUid = e.uid; 

                    promises.push(fetch(`${API_URL}/mydata/enrollment/${courseUid}/${eventUid}`)
                    .then(response => {
                        return response.json().then(enrollmentData => {
                            return { [eventUid]: enrollmentData } as EnrollmentData;
                        });
                    }));   
                })

                // // should always be true per the filter applied to courses & nature of this "three-off" quickfix
                // const courseUid = c.details.scheduledEvents[0].courseUid;
                // const eventUid = c.details.scheduledEvents[0].uid;

                // promises.push(fetch(`${API_URL}/mydata/enrollment/${courseUid}/${eventUid}`)
                //     .then(response => {
                //         return response.json().then(enrollmentData => {
                //             return { [courseUid]: enrollmentData } as EnrollmentData;
                //         });
                //     }));
            }
        });
        Promise.all(promises).then(values => {
            let newData: EnrollmentData = {};
            values.forEach(e => {
                newData = {...newData, ...e}
            });
            setData(newData);
        });
    }, []);

    const getStudentRows = (eventUid: string) => {
        if (data[eventUid]) {
            const roll = data[eventUid];

            if (roll.length === 0) {
                return (
                    <TableRow>
                        <TableCell colSpan={2}>No students enrolled</TableCell>
                    </TableRow>
                );
            } else {
                return roll.map(s =>
                    <TableRow>
                        <TableCell>{s.email}</TableCell>
                        <TableCell>{s.status}</TableCell>
                    </TableRow>
                );
            }
        } else {
            return (
                <TableRow>
                    <TableCell colSpan={2}>...loading...</TableCell>
                </TableRow>
            );
        }
    };

    return (
        <Box>
            {courses.map(c =>
                {
                    if(c.details.kind === "InPersonEvent" && c.details.scheduledEvents.length > 0) {
                        return c.details.scheduledEvents.map(e => {
                            return (
                            <Paper style={{ marginBottom: "1rem", marginTop: "1rem", padding: "1rem" }}>
                            <Typography variant="h5">{e.name}</Typography>
                            <Typography variant="subtitle1">{e.start}</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Student</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getStudentRows(e.uid)}
                                </TableBody>
                            </Table>
                        </Paper>)
                        })
                    }
                    return null;
                }
            )}
        </Box>
    );

}