import React from 'react';
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { doSilentSignInAsync } from "./authSlice";


export default function PrivateRoute({ component: Component, render, ...rest }: RouteProps) {
    const dispatch = useAppDispatch();
    const isOidcUserLoading = useAppSelector(state => state.oidc.isLoadingUser);    
    const isOidcUserLoaded = useAppSelector(state => state.oidc.user !== null);
    
    const isAuthUserLoading = useAppSelector(state => state.auth.status.includes("pending"));
    const isAuthUserLoaded = useAppSelector(state => state.auth.user !== undefined) ;
    

    if(isOidcUserLoaded && !isAuthUserLoaded) {
        dispatch(doSilentSignInAsync());
    }

    return (
        <Route
            {...rest}
            render={
                (props) => {
                    if (!isAuthUserLoaded) {
                        return isOidcUserLoading || isAuthUserLoading ? <div /> : <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />;
                    }
                    if (Component) {
                        return <Component {...props} />;
                    }
                    if (render) {
                        return render(props);
                    }
                }
            }
        />
        // <Route
        //   {...rest}
        //   render={({ location }) =>
        //     isAuthenticated ? (
        //       children
        //     ) : (
        //       <Redirect
        //         to={{
        //           pathname: "/signin",
        //           state: { from: location }
        //         }}
        //       />
        //     )
        //   }
        // />
    );
}
