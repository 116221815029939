import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Typography, Tabs, Tab, AppBar, Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip } from "@material-ui/core";

// import InfoIcon from "@material-ui/icons/InfoOutlined";
// import PrereqSatisfiedIcon from '@material-ui/icons/AssignmentTurnedIn';
// import PrereqMissingIcon from '@material-ui/icons/AssignmentLate';

import { MoreInfoIcon, PrereqMissingIcon, PrereqSatisfiedIcon } from "./CoursesIcons";
import { InfoContentMessages } from './InfoContentMessages';
import styled from "styled-components";
import CompletionCertificate from './CompletionCertificate';
import { ConditionalCoursePrerequisite, Course, CoursePrerequisite, InPersonEvent, OnlineEvent } from "./data/course";
import { UserCourse } from "./data/userCourse";
import { UserCourseHistory } from "./data/userCourseHistory";
import { useAppSelector } from "../../app/hooks";

interface CourseMoreInfoProps {
    onClose: () => void;
    open: boolean;
    course: Course;
    userCourse: UserCourse;
    history?: UserCourseHistory;
    messages: InfoContentMessages;
}

interface CourseTabProps {
    course: Course;
    userCourse: UserCourse;
    history?: UserCourseHistory;
    messages: InfoContentMessages;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ minHeight: "50vh" }}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                    {/* <Typography>{children}</Typography> */}
                </Box>
            )}
        </div>
    );
}



function getEventRegistrationTable(course: OnlineEvent | InPersonEvent) {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Session</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Available Seats</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getEventRegistrationRows(course)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
function getEventRegistrationRows(course: OnlineEvent | InPersonEvent) {
    return (
        <>
            {course.scheduledEvents.map(fe =>
                <>
                    <TableRow>
                        <TableCell colSpan={4}>{fe.name}</TableCell>
                        <TableCell>{fe.availableSeats || 1}</TableCell>
                        <TableCell><Button variant="contained" color="primary">Register</Button></TableCell>

                    </TableRow>
                    {fe.schedule.map(s =>
                        <TableRow>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell>{s.start.toLocaleString()}</TableCell>
                            <TableCell>{s.end.toLocaleString()}</TableCell>
                            <TableCell>{s.location}</TableCell>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>)}
                </>)}
        </>
    );
}

function getUserCourseHistoryTableRows(openCert: (studentName: string, completionDate: string, courseName: string) => void, courseName: string, courseHistory: UserCourseHistory) {
    switch (courseHistory.details.kind) {
        case "UserCourseHistoryOnlineSelfPacedModule":
            return (
                <>
                    {courseHistory.details.history.map((h, i) =>
                        <TableRow key={`${courseHistory.uid}-history-row-${i}`}>
                            <TableCell>{new Date(h.start).toLocaleString()}</TableCell>
                            <TableCell>{h.end ? new Date(h.end).toLocaleString() : ""}</TableCell>
                            {/* <TableCell>{h.courseMode === "Credit" ? "For Credit" : "Review"}</TableCell> */}
                            <TableCell>{h.creditReceived ? "Yes" : "No"}</TableCell>
                            <TableCell align="right">
                                {h.creditReceived && <Button type="button" color="primary" variant="contained" onClick={() => openCert("", new Date(h.end ? h.end : h.start).toLocaleDateString(), courseName)}>Open Certificate</Button>}</TableCell>
                        </TableRow>)}

                </>
            );
        default:
            return (
                <>
                    {courseHistory.details.creditReceivedOn.map((h: { date: string; }) =>
                        <TableRow>
                            <TableCell>{h.date}</TableCell>
                            <TableCell align="right"><Button type="button" color="primary" variant="contained">Open Certificate</Button></TableCell>
                        </TableRow>)}
                </>
            );
    }
}
function getUserCourseHistoryTableHeaderRowCells(courseHistory: UserCourseHistory) {
    switch (courseHistory.details.kind) {
        case "UserCourseHistoryOnlineSelfPacedModule":
            return (
                <>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                    {/* <TableCell>Mode</TableCell> */}
                    <TableCell>Credit Received</TableCell>
                    <TableCell>&nbsp;</TableCell>
                </>
            );
        default:
            return (
                <>
                    <TableCell>Credit Received On</TableCell>
                    <TableCell>&nbsp;</TableCell>
                </>
            );
    }
}
function getUserCourseHistoryTable(openCert: (studentName: string, completionDate: string, courseName: string) => void, courseName: string, courseHistory?: UserCourseHistory) {
    const hasHistory =
        courseHistory
            ? courseHistory.details.kind === "UserCourseHistoryOnlineSelfPacedModule"
                ? courseHistory.details.history.length > 0
                : courseHistory.details.creditReceivedOn.length > 0
            : false;
    if (hasHistory) {
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {getUserCourseHistoryTableHeaderRowCells(courseHistory!)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getUserCourseHistoryTableRows(openCert, courseName, courseHistory!)}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
    else {
        return (<Typography>No history available.</Typography>);
    }
}

function isCoursePrerequisite(prereq: CoursePrerequisite | ConditionalCoursePrerequisite): prereq is CoursePrerequisite {
    return (prereq as CoursePrerequisite).name !== undefined;
}
function isConditionalCoursePrerequisite(prereq: CoursePrerequisite | ConditionalCoursePrerequisite): prereq is ConditionalCoursePrerequisite {
    return (prereq as ConditionalCoursePrerequisite).logic !== undefined;
}

function getUserCoursePrereqsTableRows(course: Course, userCourse: UserCourse) {
    // TODO handle nested ConditionalCoursePrerequisites
    // TODO handle prerequisites with a takenWithinLast interval
    const tableRows: JSX.Element[] = [];

    course.requiredPrereqs?.forEach(rp => {
        rp.courses.forEach((rc, i) => {
            if (isCoursePrerequisite(rc)) {
                let satisifed = false;
                if (userCourse.latestDateCompleted && userCourse.latestDateCompleted.length > 0) {
                    satisifed = true;
                }
                if (!(i % 2 === 0)) {
                    // odd index
                    tableRows.push(
                        <TableRow>
                            <TableCell colSpan={3} align="center">{rp.logic.toUpperCase()}</TableCell>
                        </TableRow>
                    );
                }
                tableRows.push(
                    <TableRow>
                        <TableCell>
                            <Tooltip title={satisifed ? "This prerequisite has been completed" : "This prerequisite has not been completed"}>
                                {satisifed ? <PrereqSatisfiedIcon /> : <PrereqMissingIcon />}
                            </Tooltip>
                            Required
                        </TableCell>
                        <TableCell align="center">{rc.name}</TableCell>
                    </TableRow>
                );

            }
        });
    });

    // TODO DRY this up
    course.recommendedPrereqs?.forEach(rp => {
        rp.courses.forEach((rc, i) => {
            if (isCoursePrerequisite(rc)) {
                let satisifed = false;
                if (userCourse.latestDateCompleted && userCourse.latestDateCompleted.length > 0) {
                    satisifed = true;
                }
                tableRows.push(
                    <TableRow>
                        <TableCell>
                            <Tooltip title={satisifed ? "This prerequisite has been completed" : "This prerequisite has not been completed"}>
                                {satisifed ? <PrereqSatisfiedIcon /> : <PrereqMissingIcon />}
                            </Tooltip>
                            Recommended
                        </TableCell>
                        <TableCell align="center">{rc.name}</TableCell>
                    </TableRow>
                );
                if (!(i % 2 === 0)) {
                    // odd index
                    <TableRow>
                        <TableCell colSpan={3} align="center">{rp.logic.toUpperCase()}</TableCell>
                    </TableRow>;
                }
            }
        });
    });
    return tableRows;
}

function getUserCoursePrereqsTable(course: Course, userCourse: UserCourse) {
    const hasPrereqs =
        (course.recommendedPrereqs && course.recommendedPrereqs.length > 0)
        ||
        (course.requiredPrereqs && course.requiredPrereqs.length > 0);
    if (hasPrereqs) {
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell align="center">Prerequisite</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getUserCoursePrereqsTableRows(course, userCourse)}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
    return (<Typography>This course does not have any prerequisites.</Typography>);
}
function getUserCourseCompulsionRows(userCourse: UserCourse) {
    return (
        <>
            {userCourse.compulsionDetail?.map(c =>
                <TableRow>
                    <TableCell>{c.compulsion}</TableCell>
                    <TableCell>{`${c.userProfileField}: ${c.userProfileFieldValue}`}</TableCell>
                </TableRow>)}
        </>
    );
}

function getUserCourseCompulsionTable(userCourse?: UserCourse) {

    if (userCourse && userCourse.compulsionDetail && userCourse.compulsionDetail.length > 0) {
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Requirement Level</TableCell>
                            <TableCell>Reason</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getUserCourseCompulsionRows(userCourse)}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    } else if (userCourse && userCourse.compulsion === "Optional") {
        return (<Typography>This course is not required.</Typography>);
    } else {
        return (<Typography>Unable to determine requirements.</Typography>);
    }
}

interface HistoricalCourseCertificate {
    open: boolean;
    studentName: string;
    completionDate: string;
    courseName: string;

}

function CourseTabs(props: CourseTabProps) {
    // TODO: use of these next two variables is a bit of a hack, fix it 
    const authUserName = useAppSelector(state => ((state.auth.user?.profile?.givenName || "") + " " + (state.auth.user?.profile?.surname || "")));
    const courseCertificateName = typeof props.course.certificate === "object" ? props.course.certificate.name : props.course.name;

    const [selectedTab, setSelectedTab] = React.useState(0);
    const [historicalCourseCertificate, setHistoricalCourseCertificate] = React.useState<HistoricalCourseCertificate>({ open: false, studentName: "", completionDate: "", courseName: "" });
    const handleHistoricalCourseCertificateOpen = (studentName: string, completionDate: string, courseName: string) => {
        setHistoricalCourseCertificate({ open: true, studentName: studentName, completionDate: completionDate, courseName: courseName });
    };
    const handleHistoricalCourseCertificateClose = () => setHistoricalCourseCertificate({ open: false, studentName: "", completionDate: "", courseName: "" });

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <div style={{ width: "100%" }}>
            <CompletionCertificate open={historicalCourseCertificate.open} onClose={handleHistoricalCourseCertificateClose} studentName={authUserName} completionDate={historicalCourseCertificate.completionDate} courseName={courseCertificateName} />
            <AppBar position="relative">
                <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                    <Tab wrapped label="Info"></Tab>
                    <Tab wrapped label="History"></Tab>
                    <Tab wrapped label="Prerequisites"></Tab>
                    <Tab wrapped label="Requirement Level"></Tab>
                </Tabs>
            </AppBar>
            <Paper>
                <TabPanel value={selectedTab} index={0}>
                    <Typography gutterBottom>
                        {props.messages.courseStatus}
                    </Typography>
                    <Typography gutterBottom>
                        This course is {props.userCourse.compulsion.toLowerCase()}{props.userCourse.compulsion !== "Optional" && <Typography variant="caption" style={{ display: "block", marginLeft: "1rem" }}>See the Requirement Level tab for details </Typography>}
                    </Typography>

                    <Typography gutterBottom>
                        {props.messages.coursePrereqs && "This course has prerequisites"}
                        {props.messages.coursePrereqs && <Typography variant="caption" style={{ display: "block", marginLeft: "1rem" }}>See the Prerequisites tab for details</Typography>}
                    </Typography>

                    <Typography color="textSecondary">{props.course.description || ""}</Typography>
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    {getUserCourseHistoryTable(handleHistoricalCourseCertificateOpen, props.course.name, props.history)}
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                    {getUserCoursePrereqsTable(props.course, props.userCourse)}
                </TabPanel>
                <TabPanel value={selectedTab} index={3}>
                    {getUserCourseCompulsionTable(props.userCourse)}
                </TabPanel>
            </Paper>
        </div>
    );

}

const MoreInfoDialog = styled(Dialog)`
    /* min-width: 75%;
    max-width: 1100px;
    min-height: 40%;
    max-height: 90%; */
`;

export default function CourseMoreInfo(props: CourseMoreInfoProps) {

    return (
        //  style={{ zIndex: props.zIndex }}
        <MoreInfoDialog fullWidth maxWidth="lg" onClose={props.onClose} open={props.open}>
            <DialogTitle><MoreInfoIcon fontSize="large" color="primary" style={{ marginRight: "0.5rem", verticalAlign: "middle" }} />{props.course.name}</DialogTitle>
            <DialogContent>
                <CourseTabs messages={props.messages} course={props.course} userCourse={props.userCourse} history={props.history} />
            </DialogContent>
            <DialogActions>
                <Button name="close" onClick={props.onClose} color="primary">Close</Button>
            </DialogActions>
        </MoreInfoDialog>


    );
}