import React, { useEffect } from 'react';
import "./scormAPI";
import { Dialog, AppBar, Toolbar, IconButton, Typography, Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons';
import ScormIframe from './ScormIframe';
import { UserCourseStatus } from "./data/userCourse";
import { getMyScormSession, saveCMI, ScormSession } from "./myScormDataAPI";

interface ScormPlayerProps {
    onClose: () => void;
    open: boolean;
    courseName: string;
    url: string;
    // zIndex: number;
    courseResetAllowed: boolean; // should be courseReviewModeAllowed - logic for this is external (CourseInfoCard determines based on if the course allows it and if the user's completion status allows it). If true, UserCourseStatus will be completed
    courseId: string;
    userCourseStatus: UserCourseStatus;
}

export default function ScormPlayer(props: ScormPlayerProps) {
    const [showCourseReset, setShowCourseReset] = React.useState(props.courseResetAllowed);
    const [courseMode, setCourseMode] = React.useState<"review" | "credit">("credit");
    // const [userAnsweredCourseReset, setUserAnsweredCourseReset] = React.useState(false);
    const [loadingCourseSession, setLoadingCourseSession] = React.useState(true);
    const [scormSession, setScormSession] = React.useState<ScormSession | null>(null); // from server
    const [forceCreditMode, setForceCreditMode] = React.useState<"idle" | "pending" | "false" | "true" | "n/a">(props.courseResetAllowed ? "idle" : "n/a");

    useEffect(() => {
        // if the UserCourseStatus is completed, retrieve scorm session in review mode
        // if the session does not include any data, then don't offer the user the option to open the course in review mode

        if (forceCreditMode === "idle"){
            setForceCreditMode("pending");
            getMyScormSession(props.courseId, false, true).then(ss => {
                if (ss && ss.datum) {
                    // we have scorm data, user can reset if they want
                    // TODO: store the scormSession now, prevent it being retrieved again in other effect
                    setForceCreditMode("false");
                } else {
                    setShowCourseReset(false);
                    setCourseMode("credit");
                    setForceCreditMode("true");
                }
            });
        }
    }, [forceCreditMode, props.courseId]);

    useEffect(() => {
        if (props.open && (!showCourseReset || !props.courseResetAllowed)) {
            // this instance of ScormPlayer has been opened
            // and the courseReset dialog has been displayed if necessary

            let courseReset = false;
            if (courseMode === "credit" && (props.userCourseStatus === "Completed" || props.userCourseStatus === "Expired")) {
                // user either selected to reset a completed course and take for credit
                // or the user was not allowed to choose to reset the course so the course is reset automatically
                courseReset = true;
            }
            //now retrieve scorm session from server
            getMyScormSession(props.courseId, courseReset, courseMode === "review").then(ss => {
                setScormSession(ss);
                setLoadingCourseSession(false);
            });
        }
    }, [courseMode, props.courseId, props.courseResetAllowed, props.open, props.userCourseStatus, showCourseReset]);




    const handleClose = (e: React.MouseEvent) => { props.onClose(); };
    const handleForCredit = (e: React.MouseEvent) => {
        // reset session        
        setShowCourseReset(false);
        setCourseMode("credit");
        // setUserAnsweredCourseReset(true);
    };
    const handleForReview = (e: React.MouseEvent) => {
        // review mode, or just send full bookmarks                
        setShowCourseReset(false);
        setCourseMode("review");
        // setUserAnsweredCourseReset(true);
    };
    const CourseResetDialog = () => {
        return (
            // style={{ zIndex: props.zIndex }}
            <Dialog onClose={handleClose} open={props.open} >
                <DialogTitle>Course Reset</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You have previously completed this course. Would you like to open the course in review mode, or reset your progress so it can be taken for course credit?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button name="review" onClick={handleForReview} color="primary" variant="contained">Review</Button>
                    <Button name="credit" onClick={handleForCredit} color="primary" variant="contained">For Credit</Button>
                    <Button name="cancel" onClick={handleClose} color="primary" variant="contained">Cancel</Button>
                </DialogActions>
            </Dialog>
        );
    };
    const ScormPlayerDialog = () => {
        return (
            //  style={{ zIndex: props.zIndex }}
            <Dialog onClose={handleClose} open={props.open} fullScreen={true}>
                <AppBar style={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">{props.courseName}</Typography>
                    </Toolbar>
                </AppBar>
                <ScormIframe src={props.url} />
            </Dialog>
        );
    };
    const handleWindowClosing = () => {
        // may want to change method to just "Terminate"
        if (scormSession) { saveCMI(scormSession.scormSessionId, "Terminate-WindowClosed"); };
    };

    useEffect(() => {
        // https://javascript.plainenglish.io/how-to-alert-a-user-before-leaving-a-page-in-react-a2858104ca94

        if (props.open && !loadingCourseSession && scormSession && courseMode === "credit") {
            window.addEventListener("beforeunload", handleWindowClosing);
            return () => {
                window.removeEventListener("beforeunload", handleWindowClosing);
            };
        }
    });

    if (props.open) {
        if (forceCreditMode === "pending") {
            return <div>Loading...</div>;
        }

        if (showCourseReset) {
            return CourseResetDialog();
        } else {
            if (loadingCourseSession || !scormSession) {
                return <div>Loading...</div>;
            } else {

                //@ts-ignore
                window.API_1484_11.reset();

                // wireup scorm event handlers 

                if (courseMode === "credit") {
                    //@ts-ignore                    
                    window.API_1484_11.on("Commit", () => saveCMI(scormSession.scormSessionId, "Commit"));

                    //@ts-ignore
                    window.API_1484_11.on("Terminate", () => saveCMI(scormSession.scormSessionId, "Terminate"));
                }

                // load scorm datamodel
                if (scormSession.datum) {
                    //@ts-ignore           
                    const loadResult = window.API_1484_11.loadFromJSON(JSON.parse(scormSession.datum.dataModel));
                    console.log(`load Result: ${loadResult}`);
                }
                //@ts-ignore
                window.API_1484_11.apiLogLevel = 1;

                return ScormPlayerDialog();
            }
        }
    } else {
        return null;
    }
}