import React from "react";
import { UserProfileStepComponentProps } from "./userProfileConfig";
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import * as yup from 'yup';
import { orgData, OrgTreeItem } from "./orgData";
import { useAppSelector } from "../../app/hooks";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        // height: 240,
        // flexGrow: 1,
        // maxWidth: 400,
    },
});




function searchTree(node: OrgTreeItem, searchId: string): string[] | undefined {
    if (String(node.id) === searchId) {
        return [];
    }
    if (Array.isArray(node.children)) {
        for (var treeNode of node.children) {
            const childResult = searchTree(treeNode, searchId);

            if (Array.isArray(childResult)) {
                return [String(treeNode.id)].concat(childResult);
            }
        }
    }
}

function findOrgParents(searchId: string) {    
    if (searchId) {
        const tree: OrgTreeItem = {
            id: 0,
            name: "root",
            children: orgData
        };
        return searchTree(tree, searchId) || [];
        // const result = orgData.map(tree => searchTree(tree, searchVal));
        // return result;
    }
    // const searchVal = Number(searchId);
    // if (searchVal > 0) {
    //     let overallResult: number[] | undefined = undefined;
    //     orgData.forEach(tree => {
    //         if (!overallResult) {
    //             const result = searchTree(tree, searchVal);
    //             if (result)
    //                 overallResult = result;
    //         }
    //     });
    //     return overallResult;
    // }
    // return [];
}


export default function OrgTree(props: UserProfileStepComponentProps) {
    const classes = useStyles();

    const renderTree = (nodes: OrgTreeItem) => (
        <TreeItem key={nodes.id} nodeId={nodes.id.toString()} label={nodes.name}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    );
    const authUser = useAppSelector(state => state.auth.user);

    const initialValues = props.action ===
        "register" || !authUser ?
        {
            organization: ""
        }
        :
        {
            organization: authUser.profile?.organization || ""
        };




    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: yup.object({
            organization: yup.number().required("Required")
        }),
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            props.onNext.callback(values);
        },
    });
    // const expand = findOrgParents(formik.values.organization);


    // TODO: Fix controlled vs uncontrolled state for TreeView
    return (
        <div className={classes.root}>
            <form onSubmit={formik.handleSubmit}>
                <FormControl error={formik.touched.organization && Boolean(formik.errors.organization)}>                
                <TreeView
                    aria-label="controlled"
                    className={classes.root}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    selected={formik.values.organization}
                    expanded={findOrgParents(formik.values.organization)}
                    onNodeSelect={(e: any, id: any) => formik.setValues({ organization: id })}
                >
                    {orgData.map(i => renderTree(i))}
                </TreeView>
                {formik.touched.organization && Boolean(formik.errors.organization) && <FormHelperText error={Boolean(formik.errors.organization)}>{formik.errors.organization}</FormHelperText>}
                <TextField
                    id="organization"
                    name="organization"
                    value={formik.values.organization}
                    type="hidden"
                />
                </FormControl>
                <div>
                    <div>
                        <Button
                            disabled={!props.onPrev.callback}
                            onClick={props.onPrev.callback}
                        >
                            {props.onPrev.label}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {props.onNext.label}
                        </Button>
                    </div>
                </div>

            </form>
        </div>
    );
}