import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { TextField, InputAdornment, IconButton, Button } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { UserProfileStepComponentProps } from './userProfileConfig';
import { useAppSelector } from "../../app/hooks";



export default function BasicInfo(props: UserProfileStepComponentProps) {

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const authUser = useAppSelector(state => state.auth.user);

    const initialValues = props.action ===
        "register" || !authUser ?
        {
            email: "",
            password: "",
            confirmPassword: "",
            givenName: "",
            surname: ""
        }
        :
        {
            email: authUser.email,
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            givenName: authUser.profile?.givenName || "",
            surname: authUser.profile?.surname || ""
        };
    const validationSchema = props.action ===
        "register" || !authUser ?
        //New User Registration
        yup.object({
            email: yup
                .string()
                .email("Enter a valid email")
                .required("Email is required"),
            password: yup
                .string()
                .required("Password is required")
                ///^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, "Password must contain at least 12 characters, one uppercase, one lowercase, one number, and one special character."),
            confirmPassword: yup
                .string()
                .oneOf([yup.ref("password"), null], "Passwords must match"),
            givenName: yup
                .string()
                .required("Given Name is required"),
            surname: yup
                .string()
                .required("Surname is required"),
        })
        :
        yup.object({
            // user profile update - users may have an oldPassword that doesnt match requirements, but new one must
            email: yup
                .string()
                .email("Enter a valid email")
                .required("Email is required"),
            oldPassword: yup
                .string(),                
            newPassword: yup
                .string()
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, "Password must contain at least 12 characters, one uppercase, one lowercase, one number, and one special character.")
            //.min(12, "Password must be of minimum 12 characters in length")
            ,
            confirmNewPassword: yup
                .string()
                .oneOf([yup.ref("newPassword"), null], "Passwords must match")
            ,
            givenName: yup
                .string()
                .required("Given Name is required"),
            surname: yup
                .string()
                .required("Surname is required"),
        });
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            props.onNext.callback(values);
        },
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    // helperText={props.action === "userProfile" ? "Contact help@ngor-lms.com to change your email" : formik.touched.email && formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                    // disabled={props.action === "userProfile"}
                />
                {props.action === "register" &&
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="password"
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />}
                {props.action === "register" &&
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="confirmPassword"
                        name="confirmPassword"
                        label="Confirm Password"
                        type={showPassword ? "text" : "password"}
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />}
                {props.action === "userProfile" &&
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="oldPassword"
                        name="oldPassword"
                        label="Current Password"
                        type={showPassword ? "text" : "password"}
                        value={formik.values.oldPassword}
                        onChange={formik.handleChange}
                        error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                        helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />}
                {props.action === "userProfile" &&
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="newPassword"
                        name="newPassword"
                        label="New Password"
                        type={showPassword ? "text" : "password"}
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                        helperText={formik.touched.newPassword && formik.errors.newPassword}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />}
                {props.action === "userProfile" &&
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="confirmNewPassword"
                        name="confirmNewPassword"
                        label="Confirm New Password"
                        type={showPassword ? "text" : "password"}
                        value={formik.values.confirmNewPassword}
                        onChange={formik.handleChange}
                        error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                        helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />}
                <TextField
                    fullWidth
                    variant="outlined"
                    id="givenName"
                    name="givenName"
                    label="Given Name"
                    value={formik.values.givenName}
                    onChange={formik.handleChange}
                    error={formik.touched.givenName && Boolean(formik.errors.givenName)}
                    helperText={formik.touched.givenName && formik.errors.givenName}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    id="surname"
                    name="surname"
                    label="Surname"
                    value={formik.values.surname}
                    onChange={formik.handleChange}
                    error={formik.touched.surname && Boolean(formik.errors.surname)}
                    helperText={formik.touched.surname && formik.errors.surname}
                />

                <div>
                    <div>
                        <Button
                            disabled={!props.onPrev.callback}
                            onClick={props.onPrev.callback}
                        >
                            {props.onPrev.label}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {props.onNext.label}
                        </Button>
                    </div>
                </div>

            </form>
        </div>
    );
}