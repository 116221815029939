import React from 'react';
import Grid from '@material-ui/core/Grid';

function Downloads() {

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <h4>Downloads</h4>
                <p><a href='/Downloads/AGO_Form_200-1-2.pdf' target='_blank'>AGO Form 200-1-2 - ARNG Checklist</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-3.pdf' target='_blank'>AGO Form 200-1-3 - HM Inventory</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-5.pdf' target='_blank'>AGO Form 200-1-5 - Above-Ground Storage Tank Monthly Inspection</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-6.pdf' target='_blank'>AGO Form 200-1-6 - Hazardous Waste Determination Sheet</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-7.pdf' target='_blank'>AGO Form 200-1-7 - Emergency Information</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-8.pdf' target='_blank'>AGO Form 200-1-8 - Inspection Sheet</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-9.pdf' target='_blank'>AGO Form 200-1-9 - Spill Incident Report</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-11.pdf' target='_blank'>AGO Form 200-1-11 - Noise Compliant Questionnaire</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-12.pdf' target='_blank'>AGO Form 200-1-12 - Internal EPAS Checklist</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-13.pdf' target='_blank'>AGO Form 200-1-13 - Environmental Training Roster</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-14.pdf' target='_blank'>AGO Form 200-1-14 - Waste Turn-in</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-15.pdf' target='_blank'>AGO Form 200-1-15 - Waste Log Off-Site VSQG</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-16.pdf' target='_blank'>AGO Form 200-1-16 - Pesticide Application Record</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-17_Part_A.pdf' target='_blank'>AGO Form 200-1-17 Part A - Self-Help Pest Management Application Treatment Record</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-17_Part_B.pdf' target='_blank'>AGO Form 200-1-17 Part B - Self-Help Pest Management Application Treatment Record</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-18.pdf' target='_blank'>AGO Form 200-1-18 - Washrack SOP</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-19.pdf' target='_blank'>AGO Form 200-1-19 - Sample Environmental Checklist for New Employees</a></p>
                <p><a href='/Downloads/AGO_Form_200-1-20.pdf' target='_blank'>AGO Form 200-1-20 - Spill Response Drill</a></p>                
            </Grid>
        </Grid>
    );
}

export default Downloads;