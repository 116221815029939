import React from 'react';
import { FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Dialog, DialogActions, Button, DialogContent } from "@material-ui/core";

export type CoursesGroupBy = "RequirementLevel" | "Kind" | "None";

export type CoursesFilter = "All" | "NeededIncomplete";

export interface CoursesFilterSettings {
    groupBy: CoursesGroupBy;
    filter: CoursesFilter;
}

interface CoursesSettingsProps {
    open: boolean;
    onClose: (filterSettings: CoursesFilterSettings) => void;
    filterSettings: CoursesFilterSettings;
}
export default function CoursesSettings(props: CoursesSettingsProps) {
    const [groupBy, setGroupBy] = React.useState<CoursesGroupBy>(props.filterSettings.groupBy);
    const [filter, setFilter] = React.useState<CoursesFilter>(props.filterSettings.filter);

    const handleGroupByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore
        setGroupBy((event.target as HTMLInputElement).value);
    };
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore
        setFilter((event.target as HTMLInputElement).value);
    }
    return (
        <Dialog open={props.open} onClose={() => props.onClose({ groupBy: groupBy, filter: filter })}>
            <DialogContent>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Group Courses by:</FormLabel>
                    <RadioGroup name="coursesGroupBy" value={groupBy} onChange={handleGroupByChange}>
                        <FormControlLabel value="RequirementLevel" control={<Radio color="primary" />} label="Requirement Level" />
                        <FormControlLabel value="Kind" control={<Radio color="primary" />} label="Type of Course" />
                        <FormControlLabel value="None" control={<Radio color="primary" />} label="No Grouping" />
                    </RadioGroup>
                </FormControl>
                <br/>
                <br/>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Show:</FormLabel>
                    <RadioGroup name="coursesFilter" value={filter} onChange={handleFilterChange} color="primary">
                        <FormControlLabel value="All" control={<Radio color="primary" />} label="All Courses" />
                        <FormControlLabel value="NeededIncomplete" control={<Radio color="primary" />} label="Required &amp; Recommended courses that are incomplete or expired" />                        
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button name="closeCoursesSettings" onClick={() => props.onClose({ groupBy: groupBy, filter: filter })}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}