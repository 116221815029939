import React, { useEffect } from 'react';
import { useFormik, Field } from 'formik';
import * as yup from 'yup';
import { TextField, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Select, ListSubheader, MenuItem, InputLabel, Input } from "@material-ui/core";

import { UserProfileStepComponentProps } from './userProfileConfig';
import fieldsData from "../../data/userProfileFields.json";
import { Convert, FieldType, Option, UserProfileField } from '../../data/UserProfileDataTypes';
import { useAppSelector } from "../../app/hooks";
import { UserProfile } from "../auth/authAPI";
import { getValidationSchema } from "./validationSchema";



const fieldConfig = Convert.toUserProfileField(JSON.stringify(fieldsData));


const validationSchema = yup.object({
    placeholder: yup
        .string()
        .required("Placeholder is required"),
});

function fieldOptionsCompare(a: Option, b: Option) {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

function getFormikInitialValues(fieldConfig: UserProfileField[], currentUserProfile?: UserProfile) {
    const iv = Object.assign({}, ...fieldConfig.map(field => {
        if (currentUserProfile?.[field.fieldId])
            return { [field.fieldId]: currentUserProfile[field.fieldId] };
        else
            return { [field.fieldId]: field.type === FieldType.Multiselect ? [] : "" };
    }
    ));
    return iv;
}
function getFields(fieldConfig: UserProfileField[], formik: ReturnType<typeof useFormik>): React.ReactNode {
    return fieldConfig
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(field => {
            let showField = true;
            if (field.onlyShowWhen) {

                field.onlyShowWhen.forEach(v => {
                    // eslint-disable-next-line eqeqeq
                    if (formik.values[v.fieldId] != v.key) {
                        showField = false;
                    }
                });
            }
            if (!showField) {
                // reset its value so other showWhen checks don't show if this field had a value
                formik.values[field.fieldId] = ""; // formik.initialValues[field.fieldId];
            }
            const showHideFieldStyle = showField ? {} : { display: "none" };
            switch (field.type) {
                case FieldType.Multiselect:
                    return (
                        <FormControl key={field.fieldId} style={showHideFieldStyle}>
                            <InputLabel>{field.label}</InputLabel>
                            <Select
                                id={field.fieldId}
                                multiple
                                fullWidth
                                value={formik.values[field.fieldId]}
                                input={<Input />}
                                onChange={formik.handleChange(field.fieldId)}
                                onBlur={formik.handleBlur(field.fieldId)}
                                error={formik.touched[field.fieldId] && Boolean(formik.errors[field.fieldId])}
                            >
                                {
                                    [<ListSubheader key={`lsh-${field.fieldId}`}>{field.label}</ListSubheader>].concat(field.options.sort(fieldOptionsCompare).map(option => <MenuItem key={option.key} value={option.key}>{option.text}</MenuItem>))
                                }
                            </Select>
                            {field.helpText && <FormHelperText>{field.helpText}</FormHelperText>}
                            {formik.touched[field.fieldId] && Boolean(formik.errors[field.fieldId]) && <FormHelperText error={Boolean(formik.errors[field.fieldId])}>{formik.errors[field.fieldId]}</FormHelperText>}
                        </FormControl>
                    );
                case FieldType.Dropdown:
                    return (
                        <FormControl key={field.fieldId} style={showHideFieldStyle}>
                            <InputLabel>{field.label}</InputLabel>
                            <Select
                                fullWidth
                                id={field.fieldId}
                                value={formik.values[field.fieldId]}
                                onChange={formik.handleChange(field.fieldId)}
                                onBlur={formik.handleBlur(field.fieldId)}
                                error={formik.touched[field.fieldId] && Boolean(formik.errors[field.fieldId])}
                            >

                                {
                                    [<ListSubheader key={`lsh-${field.fieldId}`}>{field.label}</ListSubheader>].concat(field.options.sort(fieldOptionsCompare).map(option => <MenuItem key={option.key} value={option.key}>{option.text}</MenuItem>))
                                }

                            </Select>
                            {field.helpText && <FormHelperText>{field.helpText}</FormHelperText>}
                            {formik.touched[field.fieldId] && Boolean(formik.errors[field.fieldId]) && <FormHelperText error={Boolean(formik.errors[field.fieldId])}>{formik.errors[field.fieldId]}</FormHelperText>}
                        </FormControl>
                    );
                case FieldType.Radio:
                    return (
                        <FormControl key={field.fieldId} component="fieldset" style={showHideFieldStyle} error={formik.touched[field.fieldId] && Boolean(formik.errors[field.fieldId])}>
                            <FormLabel component="legend">{field.label}</FormLabel>
                            <RadioGroup
                                row
                                name={field.fieldId}
                                value={formik.values[field.fieldId]}
                                onChange={formik.handleChange(field.fieldId)}
                                onBlur={formik.handleBlur(field.fieldId)}
                            >
                                {
                                    field.options.map(option =>
                                        <FormControlLabel
                                            name={field.fieldId}
                                            key={option.key}
                                            value={option.key.toString()}
                                            control={<Radio color="primary" />}
                                            label={option.text}
                                        />
                                    )
                                }
                            </RadioGroup>
                            {field.helpText && <FormHelperText>{field.helpText}</FormHelperText>}
                            {formik.touched[field.fieldId] && Boolean(formik.errors[field.fieldId]) && <FormHelperText error={Boolean(formik.errors[field.fieldId])}>{formik.errors[field.fieldId]}</FormHelperText>}
                        </FormControl>
                    );
                default:
                    return <></>;

            }
        });
}

export default function AdditionalInfo(props: UserProfileStepComponentProps) {
    const currentUserProfile = useAppSelector(state => state.auth.user?.profile);
    const formik = useFormik({
        initialValues: getFormikInitialValues(fieldConfig, currentUserProfile),
        validationSchema: getValidationSchema(fieldConfig),
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            props.onNext.callback(values);
        }
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                {getFields(fieldConfig, formik)}

                <div>
                    <div>
                        <Button
                            disabled={!props.onPrev.callback}
                            onClick={props.onPrev.callback}
                        >
                            {props.onPrev.label}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {props.onNext.label}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );

    // const formik = useFormik({
    //     initialValues: {
    //         placeholder: "",
    //     },
    //     validationSchema: validationSchema,
    //     onSubmit: (values) => {
    //         //alert(JSON.stringify(values, null, 2));
    //         props.onNext.callback();
    //     },
    // });

    // return (
    //     <div>
    //         <form onSubmit={formik.handleSubmit}>
    //             <TextField
    //                 fullWidth
    //                 variant="outlined"
    //                 id="placeholder"
    //                 name="placeholder"
    //                 label="Placeholder"
    //                 value={formik.values.placeholder}
    //                 onChange={formik.handleChange}
    //                 error={formik.touched.placeholder && Boolean(formik.errors.placeholder)}
    //                 helperText={formik.touched.placeholder && formik.errors.placeholder}
    //             />
    //             <div>
    //                 <div>
    //                     <Button
    //                         disabled={!props.onPrev.callback}
    //                         onClick={props.onPrev.callback}
    //                     >
    //                         {props.onPrev.label}
    //                     </Button>
    //                     <Button
    //                         variant="contained"
    //                         color="primary"
    //                         type="submit"
    //                     >
    //                         {props.onNext.label}
    //                     </Button>
    //                 </div>
    //             </div>

    //         </form>
    //     </div>
    // );
}