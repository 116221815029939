import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
//TODO: Make this file the only one necessary to modify global styles (get rid of app.css)


const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#173049"
        },
        secondary: {
            // main: "#94BDE1"
            main: "#F2E737"
        }
    },
    typography: {
        fontFamily: "PT Sans, Arial, sans-serif"
    },
    overrides: {
        MuiAccordion: {
            root: {
                marginTop: "1rem",
            }
        },
        MuiAccordionSummary: {
            root: {
                backgroundColor: "#eaeef1",
                
                
            }
        },
        MuiList: {
            padding: {
                paddingTop: "0"
            }
        },
        MuiListSubheader: {
            sticky: {
                fontSize: "1rem",
                backgroundColor: "#DDE1E4",
                color: "#173049",
                // border: "1px solid #5F7180"
                borderTop: "1px solid #5F7180",
                borderBottom: "1px solid #5F7180"
            }
        },
        MuiTableCell: {
            head: {
                fontWeight: 600
            }
        }
    }
});

const useMuiStyles = makeStyles((theme) => ({
    root: {
        
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        '& li': {
            marginBottom: theme.spacing(1)
        },
        "& .MuiButton-text": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)            
        },
        "& .MuiFormControl-root": {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            display: "block"
        }
    }
}));

interface AppTheme {
    muiTheme: typeof muiTheme;
    useMuiStyles: typeof useMuiStyles;
}

const Theme: AppTheme = {
    muiTheme: muiTheme,
    useMuiStyles: useMuiStyles
}
export { Theme };