import * as yup from 'yup';
import { UserProfileField } from '../../data/UserProfileDataTypes';

export function getValidationSchema(fieldConfig: UserProfileField[]) {
    // TODO: Derive from profile field schema
    // const vs = yup.object(
    //     Object.assign({}, ...fieldConfig.map(field => {
    //         if(field.onlyShowWhen) {
    //         }
    //         if (field.fieldId === "FFA9B940-99C4-4A04-BE6E-3F0C5AACEB6E")
    //             return { [field.fieldId]: yup.array().min(1, "Required").required("Required") };
    //         return { [field.fieldId]: yup.number().required("Required") };
    //     }))
    // );
    // return vs;
    
    const schema = yup.object({
        // Military Duties
        "2FCF6D87-FD10-4CB1-94D4-60E80C590EA6": yup.number().required("Required"),
        //full-time guard employee
        "90727415-F957-4102-977D-544E36DD54EF": yup.number().required("Required"),
        //state employee
        "0750CE68-6AAD-46C3-B954-25ACAA71539A": yup.mixed()
            .when("90727415-F957-4102-977D-544E36DD54EF", {
                is: 201,
                then: yup.number().required("Required")
            }),
        //full-time federal employee
        "631AB676-6BE3-4128-B777-4AE6413D2147": yup.mixed()
            .when("0750CE68-6AAD-46C3-B954-25ACAA71539A", {
                is: 203,
                then: yup.number().required("Required")
            }),
        // OMD (State)
        "915B0207-16C0-4C8B-A41C-D96F1005EFDE": yup.mixed()
            .when("0750CE68-6AAD-46C3-B954-25ACAA71539A", {
                is: 202,
                then: yup.number().required("Required")
            }),
        // full-time status
        "D6D70570-DD28-48FB-A68E-6C92D2E99B32": yup.mixed()
            .when("90727415-F957-4102-977D-544E36DD54EF", {
                is: 200,
                then: yup.number().required("Required")
            }),
        // full-time status (second one)
        "9441F0AF-AEE6-4E17-823A-2EAF63F10DC7": yup.mixed()
            .when("631AB676-6BE3-4128-B777-4AE6413D2147", {
                is: 204,
                then: yup.number().required("Required")
            }),
        // ADOS Position
        "AE98001E-42B1-4530-B639-C8D1FB96840A": yup.mixed()
            .when("D6D70570-DD28-48FB-A68E-6C92D2E99B32", {
                is: 2,
                then: yup.number().required("Required")
            }),
        // Wage Grade Position
        "772F3A83-64A8-40B9-A3CF-5F62710EB4FA": yup.mixed()
            .when("9441F0AF-AEE6-4E17-823A-2EAF63F10DC7", {
                is: 3,
                then: yup.number().required("Required")
            }),
        // General Schedule Position
        "69A1A490-FC90-42E5-B5D8-19D0627DD5E7": yup.mixed()
            .when("9441F0AF-AEE6-4E17-823A-2EAF63F10DC7", {
                is: 2,
                then: yup.number().required("Required")
            }),
        // AGR Position
        "794C726B-94E1-4AE5-9969-1F919D89386A": yup.mixed()
            .when("D6D70570-DD28-48FB-A68E-6C92D2E99B32", {
                is: 1,
                then: yup.number().required("Required")
            }),
        // MOS Type
        "5EB73E3A-DDD3-40B1-966F-040255A7773E": yup.mixed()
            .when("2FCF6D87-FD10-4CB1-94D4-60E80C590EA6", {
                is: 198,
                then: yup.number().required("Required")
            }),
        // Primary MOS Enlisted
        "1C46A95C-1463-49C0-BB05-C885C3CC3FB5": yup.mixed()
            .when("5EB73E3A-DDD3-40B1-966F-040255A7773E", {
                is: 1,
                then: yup.number().required("Required")
            }),
        // Primary MOS Warrant
        "841F1263-13EE-44C4-A9C1-1362970B4BB5": yup.mixed()
            .when("5EB73E3A-DDD3-40B1-966F-040255A7773E", {
                is: 2,
                then: yup.number().required("Required")
            }),
        // Primary MOS Officer
        "5A1C9CD0-9C5E-40BA-9909-F99412B72D8B": yup.mixed()
            .when("5EB73E3A-DDD3-40B1-966F-040255A7773E", {
                is: 3,
                then: yup.number().required("Required")
            }),
        // Facility
        "FFA9B940-99C4-4A04-BE6E-3F0C5AACEB6E": yup.array().min(1, "Required").required("Required"),
        // EPOC
        "dc024ff8-7046-422f-8628-fcd7b247a72e": yup.number().required("Required"),
        // Work at shop
        "f2cc7462-c956-41ad-bab6-1e3bb6c84f43": yup.mixed()
            .when("dc024ff8-7046-422f-8628-fcd7b247a72e", {
                is: 1,
                then: yup.number().required("Required")
            }),
        // Handles HW
        "482425c2-1394-4788-ba9a-ef341c8886f3": yup.number().required("Required"),
        // CSMS EPOC
        "29e85428-6d03-4d9f-b07e-8255fc462232": yup.number().required("Required"),
        // EPOC supervisor
        "5744bda3-9db6-4dbd-b2dc-a876b6c46dd4": yup.number().required("Required")
    });

    return schema;
}
