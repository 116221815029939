import React from "react";
import NavMenu, { NavItem } from "../elements/TopNavMenu/TopNavMenu";

const nav: NavItem[] = [
    {label: "Home", to: "/"},
    {label: "User Profile", to: "/userprofile"},
    {label: "Courses", to: "/courses"},
    {label: "Downloads", to: "/downloads"},
    {label: "Sign Out", to: "/signin", group: "right" }

];

function TestPage() {
    return (
        <div style={{backgroundColor: "peachpuff", width: "100%", height: "3rem" }}>
            <NavMenu NavItems={nav} />

        </div>
    );
}

export default TestPage;
