import { AuthCredentials } from ".";
import { AUTH_URL, API_URL } from "../../app/runtimeConstants";

import userManager from "./oidcConfig";
// TODO: Move this interface elsewhere

// TODO: Make an API base class - clean this non-DRY mess up
export interface AuthUser {
    uid: string;
    email: string;
    profile?: UserProfile;
}
export interface UserProfile {
    givenName: string;
    surname: string;
    organization: any;
    [key: string]: any;
}

export interface PasswordChange {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

//credentials: AuthCredentials, userProfile:any
export async function updateUserProfile(userProfile: UserProfile, token: string, newPassword?: PasswordChange, newEmail?: string) {
    if(newEmail) {
        // update email address
        let formData = new FormData();
        formData.append("NewEmail", newEmail);

        const response = await fetch(`${AUTH_URL}/identity/account/manage/email?handler=ChangeEmail`, {
            method: "post",
            headers: { 'Authorization': `Bearer ${token}` },
            credentials: "include",
            body: formData
        });

        if (!response.ok) {
            console.log(`Change Email ${response.status} Error ${JSON.stringify(response)}`);
            let responseBody = await response.text();
            return Promise.reject(new Error(responseBody));
        }
    }

    if (newPassword 
            && newPassword.oldPassword            
            && newPassword.newPassword
            && newPassword.newPassword.length >= 12
            && newPassword.confirmNewPassword === newPassword.newPassword) {
        // only update the password if the user entered an old, new, and confirmNew password 
        
        let formData = new FormData();
        formData.append("OldPassword", newPassword.oldPassword);
        formData.append("NewPassword", newPassword.newPassword);        
        formData.append("ConfirmPassword", newPassword.confirmNewPassword);

        const response = await fetch(`${AUTH_URL}/identity/account/manage/changepassword`, {
            method: "post",
            headers: { 'Authorization': `Bearer ${token}` },
            credentials: "include",
            body: formData
        });

        if (!response.ok) {
            console.log(`Change Password ${response.status} Error: ${JSON.stringify(response)}`);
            let responseBody = await response.text();
            return Promise.reject(new Error(responseBody));
        }
    }

    // send userProfile to api
    let formData = new FormData();
    formData.append("userProfile", JSON.stringify(userProfile)); //`'${JSON.stringify(userProfile)}'`
    const userProfileResponse = await fetch(`${API_URL}/mydata/userProfile`, {
        method: "POST",
        headers: { 'Authorization': `Bearer ${token}` },
        credentials: "include",
        body: formData
    });

    if (userProfileResponse.ok) {
        const userProfileData = await userProfileResponse.json();
        const authUser: AuthUser = {
            uid: userProfileData.uid,
            email: userProfileData.email,
            profile: JSON.parse(userProfileData.profile)
        };
        return authUser;
    } else {
        console.log(`User Profile Update ${userProfileResponse.status} Error: ${JSON.stringify(userProfileResponse)}`);
        if (userProfileResponse.status === 400) {
            // bad request
            let responseBody = await userProfileResponse.text();
            return Promise.reject(new Error(responseBody));
        }
        return Promise.reject(new Error(`Unable to update user profile. Please try again.`));
    }
}
export async function doRegister(credentials: AuthCredentials, userProfile: any) {
    // const values= {
    //     email: credentials.email,
    //     password: credentials.password,
    //     userProfile: userProfile
    // };

    try {
        let formData = new FormData();
        formData.append('Email', credentials.email);
        formData.append('Password', credentials.password);
        formData.append('ConfirmPassword', credentials.confirmPassword || "");

        const response = await fetch(`${AUTH_URL}/identity/account/register`, {
            method: "post",
            credentials: "include",
            body: formData
        });
        if (response.ok) {
            const data = await response.json();
            const authUser: AuthUser = {
                uid: data.uid,
                email: data.email,
                profile: data.profile
            };
            const user = await userManager.signinSilent();

            // send userProfile to api
            const token = user.access_token;
            formData = new FormData();
            formData.append("userProfile", JSON.stringify(userProfile)); //`'${JSON.stringify(userProfile)}'`
            const userProfileResponse = await fetch(`${API_URL}/mydata/userProfile`, {
                method: "POST",
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                credentials: "include",
                body: formData
            });
            // TODO: create a generic JsonDatumDTO class that when schema is userprofile, the data has given, surname etc
            const userProfileData = await userProfileResponse.json();
            authUser.profile = JSON.parse(userProfileData.profile);
            return authUser;
        } else {
            console.log(`Register ${response.status} Error: ${JSON.stringify(response)}`);
            if (response.status === 400) {
                // bad request
                let responseBody = await response.text();
                return Promise.reject(new Error(responseBody));
            }
            return Promise.reject(new Error(`Unable to register. Please try again.`));
        }
    }
    catch (error) {
        // fetch threw an exception (general network error)
        console.log(`Register Network Error: ${error}`);
        return Promise.reject(new Error(`Unable to register; a network error was encountered. Please try again later.`));
    }
}

export async function doSignOut() {
    await userManager.signoutRedirect();
}

export async function doSilentSignin() {
    // should only be called when the page is refreshed and after the OIDC state finishes loading
    
    const user = await userManager.signinSilent(); // { useReplaceToNavigate: true, data: state }
    const token = user.access_token;
    // get user info from API
    const response = await fetch(`${API_URL}/mydata/user`, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        credentials: "include"
    });
    const data = await response.json();
    const authUser: AuthUser = {
        uid: data.id,
        email: data.email,
        profile: JSON.parse(data.profile)
    };
    return authUser;
}

export async function doSignIn(email: string, password: string) {

    let formData = new FormData();
    formData.append('Email', email);
    formData.append('Password', password);

    // let returnUrl = encodeURI(`${window.location.origin}/signin-callback`); // where to go on success
    // const returnUrl = window.location.origin;

    try {

        const response = await fetch(`${AUTH_URL}/identity/account/login`, {
            method: "post",
            credentials: "include",
            body: formData
        });
        // a successful login responds with a 302 redirect - need to process signin at that point 

        if (response.ok) {
            // perform silent login            
            const user = await userManager.signinSilent(); // { useReplaceToNavigate: true, data: state }
            const token = user.access_token;
            // get user info from API
            const response = await fetch(`${API_URL}/mydata/user`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                credentials: "include"
            });
            const data = await response.json();
            const authUser: AuthUser = {
                uid: data.id,
                email: data.email,
                profile: JSON.parse(data.profile)
            };
            return authUser;
            // console.log(JSON.stringify(data));
            // const data = await response.json(); // won't ever be JSON when a redirect happens (302, which is "Ok")
            // // TODO: compare the parse code to what quicktype generates
            // if (data && data.uid && data.email) {
            //     return data as AuthUser;
            // } else {
            //     console.log(`SignIn malformed AuthUser data: ${JSON.stringify(data)}`);                
            //     return Promise.reject(new Error(`An unexpected error occured. Please try again.`));
            // }
        } else {
            // a non-Ok response was recieved
            console.log(`SignIn ${response.status} Error: ${JSON.stringify(response)}`);
            if (response.status === 400) {
                // bad request
                return Promise.reject(new Error(await response.text()));
            }
            return Promise.reject(new Error(`Unable to sign in. Please try again.`));
        }
    } catch (error) {
        // fetch threw an exception (general network error)
        console.log(`SignIn Network Error: ${error}`);
        return Promise.reject(new Error(`Unable to sign in; a network error was encountered. Please try again later.`));
    }


    // return new Promise<AuthUser>(
    //     (resolve, reject) => {
    //         if (email === "test@selign.com" && password === "longpassword")
    //             resolve({
    //                 uuid: "123a3917-d267-4d65-82a3-baf19a49afba",
    //                 email: "test@selign.com",
    //                 profile: {}
    //             });

    //         reject("Invalid Email and/or Password");
    //     }
    // );
}