import { SignoutCallbackComponent } from "redux-oidc";
import { useHistory } from "react-router-dom";
import userManager from "./oidcConfig";
import { useAppDispatch } from "../../app/hooks";
export default function SignOutCallbackPage() {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const callback = (error?:any) => {
        dispatch({type: "USER_SIGNOUT"}); // action destroys data in redux store
        error && console.log(`SignOutCallback error: ${error}`);
        history.replace("/signin");
    } 
    return (
        <SignoutCallbackComponent
            userManager={userManager}
            successCallback={callback}
            errorCallback={(error) => callback(error)}
        >
            <div></div>
        </SignoutCallbackComponent>
    );
}