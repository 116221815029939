import React from 'react';
import { TextField, FormControlLabel, Checkbox, Button, Typography, Snackbar } from '@material-ui/core';
import { Link as RouterLink, useHistory, useLocation, Redirect } from 'react-router-dom';
import ForgottenPassword from "./ForgottenPassword";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { doSignInAsync } from ".";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';


function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function SignInForm() {
    //TODO: check query params for pwr=success
    const dispatch = useAppDispatch();
    const location = useLocation<{ from: { pathname: string; }; }>();
    const { from } = location.state || { from: { pathname: "/" } };
    const authUser = useAppSelector(state => state.auth);
    const authError = authUser.error;
    
    const [fpOpen, setFpOpen] = React.useState(false); //Forgotten Password - Dialog    
    const handleFpClickOpen = () => { setFpOpen(true); };

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [rememberMe, setRememberMe] = React.useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;

        switch (target.name) {
            case "email":
                setEmail(target.value);
                break;
            case "password":
                setPassword(target.value);
                break;
            case "rememberMe":
                setRememberMe(target.checked);
                break;
        }
    };

    
    const handleFpClose = () => {
        setFpOpen(false);
    };

    const handleSignInClick = () => {
        dispatch(doSignInAsync({ email, password, redirectTo: from.pathname }));        
    };

    return (

        <div>
            {/* <form noValidate autoComplete="off" method="post" action={`${AUTH_URL}identity/account/login?returnUrl=${window.location.origin}/signin-callback`}> */}
            <form noValidate autoComplete="off">
                {authError && <Typography color="error">{authError}</Typography>}
                <TextField name="email" variant="outlined" label="Email" type="email" fullWidth value={email} onChange={handleInputChange}></TextField>
                <TextField name="password" variant="outlined" label="Password" type="password" fullWidth value={password} onChange={handleInputChange}></TextField>
                <FormControlLabel
                    style={{ visibility: "hidden" }}
                    control={<Checkbox name="rememberMe" color="primary" value={rememberMe} onChange={handleInputChange} disabled={true} />}
                    label="Remember me"
                />            
                <Button variant="contained" color="primary" style={{ float: "right" }} onClick={handleSignInClick}>Sign In</Button>
            </form>
            <Button color="primary" onClick={handleFpClickOpen}>Forgot your password?</Button>
            <Button color="primary" component={RouterLink} to="/register">Register as a new user</Button>
            <br />
            <Button color="primary" href="mailto:help@ngor-lms.com">Need Assistance?</Button>
            
            <ForgottenPassword onClose={handleFpClose} open={fpOpen} initialEmail={email} />

            {!authError && authUser.isAuthenticated && <Redirect to="/" />}
        </div>

    );
}