import React from "react";
import AdditionalInfo from "./AdditionalInfo";
import BasicInfo from "./BasicInfo";
import OrgTree from "./OrgTree";


export interface UserProfileStepComponentProps {
    action: "register" | "userProfile";
    onNext: {
        callback: (values?: any) => void;
        label: string;
    };
    onPrev: {
        callback?: () => void;
        label: string;
    };
}

interface UserProfileStep {
    name: string;
    description: string;
    component: (props: UserProfileStepComponentProps) => JSX.Element;

}
export const UserProfileSteps: UserProfileStep[] = [
    {
        name: "General Information",
        description: "Please complete the following information.",
        component: BasicInfo
    },
    { name: "Organization", description: "Please select one organization. Full-time ORARNG/OMD employees select your primary organization.", component: OrgTree },
    { 
        name: "Additional Information", 
        description: "Please complete the following information.", 
        component: AdditionalInfo ,
        
        
    }
];

