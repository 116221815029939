import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { doSignOutAsync } from "./authSlice";

export default function SignOutPage() {

    // const dispatch = useDispatch();
    const dispatch = useAppDispatch();    
    
    dispatch(doSignOutAsync());
    

    return (
        <div style={{width: "100%", textAlign: "center"}}>... signing out ...</div>
    )
}