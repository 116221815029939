import React, { useEffect } from 'react';
import { debounce } from 'lodash';
export function useWillContentsWrap(ref: React.RefObject<HTMLDivElement>, padding: number, returnValueOnUndefined: boolean) {

    /*
        - ref is the container div for which we are checking to see if it wide enough to hold its children without wrapping            
        - padding is an additional value to add to the widths of the children
    */

    const [containerWidth, setContainerWidth] = React.useState(ref?.current?.offsetWidth);
    const [childrenWidth, setChildrenWidth] = React.useState<number | undefined>(undefined);

    const onWindowResize = debounce(function() {
        if (ref.current) {
            setContainerWidth(ref.current.offsetWidth);
        }
    }, 100);

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);
        return () => { window.removeEventListener("resize", onWindowResize) };
    });

    useEffect(() => {
        if (ref.current) {
            setContainerWidth(ref.current.offsetWidth);

            let accumulator = 0;
            for (let i = 0; i < ref.current.children.length; i++) {
                accumulator += ref.current.children[i].scrollWidth;
            }

            setChildrenWidth(accumulator);
        }
    }, [ref]);
    
    if(containerWidth && childrenWidth) {
        return childrenWidth + padding > containerWidth;
    } else {
        return returnValueOnUndefined;
    }
}