import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import PrivateRoute from "./features/auth/PrivateRoute";
import SignInPage from './features/auth/SignInPage';
import styled from 'styled-components';
import Home from './components/pages/Home/Home';

import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import Downloads from './components/pages/Downloads/Downloads';
import UserProfilePage from './features/userProfile';
import Courses from './features/courses';
import TestPage from './components/pages/TestPage';
import Header from './components/elements/Header';
import { Theme } from './components/Theme';
import { ThemeProvider } from "@material-ui/core";

import SignOutCallbackPage from "./features/auth/SignOutCallbackPage";
import SignOutPage from "./features/auth/SignOutPage";
import { useAppSelector } from "./app/hooks";
import ResetPassword from "./features/auth/ResetPassword";
import Enrollment from "./features/courses/Enrollment";

const Content = styled.div`
margin: 0 2rem 0rem 2rem;
padding-bottom: 1rem;
@media (max-width: 650px) {
  margin: 0 1rem 0rem 1rem;
}
@media (max-width: 320px) {
  margin: 0 0.5rem 0.5rem;
}
`;
function App() {
  // const user = useAppSelector(selectAuthUser);  
  // const user = useAppSelector(selectAuthUser);
  // const auth = useAppSelector(state => state.oidc);
  // console.log(`AuthUser: ${JSON.stringify(auth)}`);
  // userManager.getUser().then(val => console.log(`getUser: ${JSON.stringify(val)}`));

  return (
    <Router>
      <Switch>
        <Route path="*">
          <AppFadein />
        </Route>
      </Switch>
    </Router>
  );
}


function AppFadein() {
  const location = useLocation();
  const muiClasses = Theme.useMuiStyles();
  return (

    <div className={`app-container ${muiClasses.root}`}>
      <ThemeProvider theme={Theme.muiTheme}>
        <Header />
        <Content>
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              classNames="fade"
              timeout={300}
            >
              <Switch location={location}>
                {/* <Route path="/silent-callback">
                  <ProcessSilentRenew></ProcessSilentRenew>
                </Route>
                <Route path="/signin-callback">
                  <CallbackPage></CallbackPage>
                </Route> */}
                <Route path="/test">
                  <TestPage></TestPage>
                </Route>
                <Route path="/register">
                  <UserProfilePage action={"register"}></UserProfilePage>
                </Route>
                <Route path="/signin">
                  <SignInPage></SignInPage>
                </Route>
                <Route path="/signout">
                  <SignOutPage></SignOutPage>
                </Route>
                <Route path="/signout-callback">
                  <SignOutCallbackPage></SignOutCallbackPage>
                </Route>
                <Route path="/resetpassword">
                  <ResetPassword />
                </Route>
                <Route path="/enrollment">
                  <Enrollment />
                </Route>
                <PrivateRoute path="/downloads" component={Downloads} />
                <PrivateRoute path="/userprofile" render={() => <UserProfilePage action={"userProfile"} />} />
                <PrivateRoute path="/courses" component={Courses} />
                <PrivateRoute exact path="/" component={Home} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </Content>
      </ThemeProvider>
    </div>

  );
}

export default App;
