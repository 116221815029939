import React from 'react';
import styled from 'styled-components';

const StyledScormIframe = styled.iframe`
    position: fixed;
    top: 64px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: calc(100% - 64px);
    margin: 0;
    padding: 0;    
`;

interface ScormIframeProps {
    src: string;
    // TOP offset?
}
export default function ScormIframe(props: ScormIframeProps) {
    return (
        <StyledScormIframe src={props.src} title="SCO"></StyledScormIframe>
    );
}