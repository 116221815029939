import { API_URL } from "../../app/runtimeConstants";
import { userManager } from "../auth";

// TODO: Generic API an error handling for this specific one
export interface ScormSession {
    scormSessionId: number;   
    status: string;
    courseId: string;
    userId: string;
    datum: ScormDatum
}

export interface ScormDatum {
    scormDataId: number;
    scormSessionId: number;
    scormMethod: string;
    dataModel: string;
}

export async function getMyScormSession(courseId: string, reset: boolean, reviewMode: boolean) {
    

    const token = (await userManager.getUser())?.access_token;
    
    const response = await fetch(`${API_URL}/mydata/scorm/session/${courseId}?reset=${reset ? "true" : "false"}&reviewMode=${reviewMode ? "true" : "false"}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'credentials': 'include'
        }        
    });

    const data: ScormSession = await response.json();
    return data;
}

export async function saveCMI(scormSessionId: number, scormMethod: string) {
    const token = (await userManager.getUser())?.access_token;

    //@ts-ignore
    const cmiData = window.API_1484_11.cmi.toJSON();

    const data = {
        scormSessionId: scormSessionId,
        scormMethod: scormMethod,
        dataModel: JSON.stringify(cmiData)
    }

    const response = await fetch(`${API_URL}/mydata/scorm/datum`, {
        method: "post",
        headers: {
            'Authorization': `Bearer ${token}`,
            'credentials': 'include',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    const ssd: ScormDatum = await response.json();
    return ssd;
}