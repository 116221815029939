import { UserProfile } from "../auth/authAPI";
import { CourseSummary } from "./coursesAPI";
import { Compulsion } from "./data/userCourse";

export interface CourseRequirementGroup {
    uid: string;
    name: string;
    description: string;
    repeatEveryXMo: number; // requirement must be repeated every X months. 0 to disable.
    satisfiedWhen: "one-complete" | "all-complete";
    courseUids: string[];
    conditions: { uid: string, value: string; }[]; // "or" logic; when any one condition is true, group is required - uid is for user profile. an Empty array matches everyone
}

export const crgs: CourseRequirementGroup[] = [    
    {
        uid: "b21cdb44-3789-47a0-a048-d94184a0bde4",
        name: "Annual Hazardous Waste Training",
        description: "Complete at least one of the following courses every 12 months.",
        repeatEveryXMo: 12,
        satisfiedWhen: "one-complete",
        courseUids: [
            "858A6F0B-A023-4876-9F6B-43CCB05E13F0", // Resource Conservation and Recovery Act (RCRA) Training
            "62816578-266D-488A-B15F-1A344812F210", // Camp Rilea HW Training
            "CCE558FA-866B-4319-94D1-532AD9A5992F", // Hazardous Waste Online Training 
            "7ED3AE12-4AD6-463D-BD77-CEA8CA7BF635", // CSMS Section EPOC Training
        ],
        conditions: [
            { uid: "482425c2-1394-4788-ba9a-ef341c8886f3", value: "1" }, // Handles HW - Yes
            { uid: "f2cc7462-c956-41ad-bab6-1e3bb6c84f43", value: "1" }, // Shop EPOC - Yes
        ]
    },
    {
        uid: "7bf2a7ca-2566-4f42-84a9-ad913871ee3d",
        name: "Environmental Compliance Training for Supervisors",
        description: "Complete at least one of the following courses every 36 months.",
        repeatEveryXMo: 36,
        satisfiedWhen: "one-complete",
        courseUids: [
            "87A80F41-7471-4301-AA3B-4C87305C9518", // Environmental Compliance Training for Supervisors 
            "CC41734B-4B07-4F3C-AE6B-F2B01AC2B809", // EPOC - Instructor Led Training
            "6BC60C29-2B31-494C-8002-F8564EB46B1D", // EPOC - Refresher Training
            "9998B7D8-A367-4703-9E26-E2D1D544B378", // EPOC - Online Training - Part 1
            "0AF80753-BBAD-457F-8A41-B8D4933F3EC2", // EPOC - Online Training - Part 2
        ],
        conditions: [
            { uid: "5744bda3-9db6-4dbd-b2dc-a876b6c46dd4", value: "1" } // EPOC Supervisor - Yes            
        ]
    },
    {
        uid: "5744bda3-9db6-4dbd-b2dc-a876b6c46dd4",
        name: "EPOC Training",
        description: "Complete at least one of the following courses every 36 months.",
        repeatEveryXMo: 36,
        satisfiedWhen: "one-complete",
        courseUids: [
            "CC41734B-4B07-4F3C-AE6B-F2B01AC2B809", // EPOC - Instructor Led Training
            "6BC60C29-2B31-494C-8002-F8564EB46B1D", // EPOC - Refresher Training
            "9998B7D8-A367-4703-9E26-E2D1D544B378", // EPOC - Online Training - Part 1
            "0AF80753-BBAD-457F-8A41-B8D4933F3EC2", // EPOC - Online Training - Part 2
        ],
        conditions: [
            { uid: "dc024ff8-7046-422f-8628-fcd7b247a72e", value: "1" }, // isEPOC - Yes
            { uid: "f2cc7462-c956-41ad-bab6-1e3bb6c84f43", value: "1" }, // isShopEPOC - Yes
        ]
    },
    {
        uid: "c9613306-7794-47b5-b6f9-55c0c4e774e2",
        name: "Annual Environmental Awareness Training",
        description: "Complete at least one of the following courses every 12 months.",
        repeatEveryXMo: 12,
        satisfiedWhen: "one-complete",
        courseUids: [ // every course
            "CC41734B-4B07-4F3C-AE6B-F2B01AC2B809",
            "858A6F0B-A023-4876-9F6B-43CCB05E13F0",
            "7ED3AE12-4AD6-463D-BD77-CEA8CA7BF635",
            "62816578-266D-488A-B15F-1A344812F210",
            "BA4ACFE6-56EB-4AD4-9829-2F40F676C472",
            "9A8B52B4-A968-4A1C-8046-A7ACAAB201F0",
            "8532AFF4-408D-461F-8B7A-F23986D47FE8",
            "D0309097-8173-43F0-8A80-E47ECFD3E378",
            "EF20683D-6ADE-4FCF-A731-07C3622B9D1A",
            "8566ECA3-AFD3-445B-8E1D-8607F9D1A4B2",
            "5D8E17EE-5EB7-4381-9865-9EA5B556235D",
            "B33F3245-CA1B-4974-B446-A4E1A6FF23C6",
            "2F9D46D0-50F3-418D-AB2C-D86384F62788",
            "CCE558FA-866B-4319-94D1-532AD9A5992F",
            "87A80F41-7471-4301-AA3B-4C87305C9518",
            "9998B7D8-A367-4703-9E26-E2D1D544B378",
            "0AF80753-BBAD-457F-8A41-B8D4933F3EC2",
            "6BC60C29-2B31-494C-8002-F8564EB46B1D",
            "525B96C0-35CB-489B-A1E8-872E801A2266",
            "72CBE71E-CA58-4B58-A566-BB292B3F51E7",
            "32D245F5-36BA-4D69-B6A8-B3408B8E4C02",
            "E85CBAD0-6FA3-4A43-99D6-A6735FF454AA",
            "DCFDDACA-7278-4074-AC86-3AF5956B2E85",
            "2AB3E253-D149-4313-904C-2D5CD816424C",
            "F6440A95-BC61-4081-A222-8C88808F11E3",
            "F1D601DF-9D4C-459D-8C6A-949BA26908B3",
            "D2D44BA0-8E4A-4A34-B0FC-C8439C6E96CB",
            "3BF1B0C6-BC1B-4112-9F09-6BD6AA260769",
            "317434F7-8A2C-4E8B-B9A1-880BC0404BCC",
            "33A53340-F3D0-409F-A254-D9FBEF6C7EEB",
            "8FC7A3B8-75B6-47EE-9F89-ABCA5BEA5D46",
            "BA4BA820-BB5F-4C18-82CD-821558B025A8",
            "193F79F9-2FAD-4BBE-B100-41035436AAFC",
            "6020B282-E63A-45BC-B084-B27684E35328",
            "B85B8BFE-3BEA-40E0-A60B-721AB9A4FDFD",
            "86BD1D43-F94F-4D62-80F8-215EB20A64F7",
            "0B3315B2-8616-47A2-A350-8BE115764203",
            "B17549C9-F9F1-4202-AE46-CA0DCDA73993",
            "F9B7E23C-4152-4D4F-B6D5-19F589E79ECF",
            "A1FDD60F-DE7F-4AE1-8637-BC3FEF33D6AB",
            "28c09b6f-1d42-4ab5-b785-cbaa12130edf",
            "70b922cd-a8a3-4b08-806e-400fcd322552",
            "3587470B-3153-473C-8148-8793437BB71C",
            "F4F556FF-6FAF-4EEF-9071-59DD93D9376C",
            "869B2631-A17A-4124-8A12-DE0B9043B2BD",
            "EA834294-AF99-4B62-93AA-3EBBECFF887A",
            "BBD27281-2F1B-4C94-879D-E1B060892459",
            "A061F2D1-E6A9-4FA6-ACAE-3DCB23224705",
            "25071CA8-5E25-40B3-A7BE-0F42C772D522",
            "E2DBEDD3-F1CE-440E-A46B-D81DAAED336A",
            "21EF4B6A-E9CD-46F0-B0FC-8E1B3BCD058A"
        ],
        conditions: []
    }
];

export interface UserCourseRequirementGroup extends CourseRequirementGroup {
    isSatisfied: boolean;
    satisfiedUntil: string;
}

export default function getUserCourseRequirementGroups(userProfile: UserProfile, courseSummaries: CourseSummary[]): UserCourseRequirementGroup[] {
    let ucrgs: UserCourseRequirementGroup[] = [];
    crgs.forEach(ucrg => {
        let ucrgAdded = false;
        if (ucrg.conditions.length === 0) {
            ucrgs.push({...ucrg, ...isCourseRequirementGroupSatisified(courseSummaries, ucrg)});
            ucrgAdded = true;
        }
        else {
            ucrg.conditions.forEach(condition => {
                if (!ucrgAdded) {
                    if (userProfile[condition.uid] === condition.value) {                        
                        ucrgs.push({...ucrg, ...isCourseRequirementGroupSatisified(courseSummaries, ucrg)});
                        ucrgAdded = true;
                    }
                }
            });
        }
    });
    return ucrgs;
}

function isCourseRequirementGroupSatisified(courseSummaries: CourseSummary[], courseRequirementGroup: CourseRequirementGroup): { isSatisfied: boolean, satisfiedUntil: string; } {
    let oneComplete = false;
    let allComplete = true;
    let latestPossibleSatisfiedUntil = "";

    courseRequirementGroup.courseUids.forEach(courseUid => {
        const cs = courseSummaries.find(c => c.course.uid === courseUid);
        if (cs && cs.course.uid !== "9998B7D8-A367-4703-9E26-E2D1D544B378") { 
            // skips EPOC Part 1
            //TODO : Add multi-part course definition to schema
            if (cs.userCourse.latestDateCompleted) {
                const latestDateCompleted = new Date(cs.userCourse.latestDateCompleted);
                if (courseRequirementGroup.repeatEveryXMo === 0) {
                    // no need to take expiry into account
                    oneComplete = true;
                } else {
                    // must consider expiry                    
                    const xMoAgo = addMonths(new Date(), -courseRequirementGroup.repeatEveryXMo);
                    if (latestDateCompleted >= xMoAgo) {
                        // course is not expired by crg logic (may be expired by course logic)
                        oneComplete = true;
                        if (!latestPossibleSatisfiedUntil) {
                            latestPossibleSatisfiedUntil = addMonths(latestDateCompleted, courseRequirementGroup.repeatEveryXMo).toLocaleDateString();
                        } else {
                            const dExistingSatisifed = new Date(latestPossibleSatisfiedUntil);
                            const dNewSatisfied = addMonths(latestDateCompleted, courseRequirementGroup.repeatEveryXMo);
                            if (dNewSatisfied >= dExistingSatisifed)
                                latestPossibleSatisfiedUntil = dNewSatisfied.toLocaleDateString();
                        }
                    } else {
                        // course is expired by crg logic
                        allComplete = false;
                    }
                }
            } else {
                // course does not have a latestDateCompleted
                allComplete = false;
            }

        }
    });
    if (courseRequirementGroup.satisfiedWhen === "all-complete") {
        return { isSatisfied: allComplete, satisfiedUntil: allComplete ? latestPossibleSatisfiedUntil : "" };
    }
    if (courseRequirementGroup.satisfiedWhen === "one-complete") {
        return { isSatisfied: oneComplete, satisfiedUntil: oneComplete ? latestPossibleSatisfiedUntil : "" };
    }
    return { isSatisfied: false, satisfiedUntil: "" };
}

function addMonths(date: Date, months: Number) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
        date.setDate(0);
    }
    return date;
}