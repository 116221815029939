export interface OrgTreeItem {
    id: number;
    name: string;
    children?: OrgTreeItem[];
}


export const orgData: OrgTreeItem[] =
    [   
        {
            id: 165,
            name: "ORARNG",
            children: [

                {
                    id: 249, name: "41 Infantry Brigade", children: [
                        { id: 250, name: "1-186 Infantry" },
                        { id: 252, name: "1-82 Cavalry" },
                        { id: 251, name: "141 Brigade Support Battalion" },
                        { id: 253, name: "2-162 Infantry" },
                        { id: 254, name: "2-218 Field Artillery" },
                        { id: 255, name: "741 Brigade Engineer Battalion" },

                    ]
                },
                {
                    id: 256, name: "82 Troop Brigade", children: [
                        { id: 257, name: "1249 Engineer Battalion" },
                        { id: 258, name: "2-641 Aviation" },
                        { id: 259, name: "3-116 Cavalry" },
                        { id: 260, name: "821 Troop Command Battalion" },
                    ]
                },
                { id: 283, name: "Financial Administrative Division" },
                { id: 285, name: "Human Resources Division" },
                {
                    id: 286, name: "Installations Division", children: [
                        { id: 287, name: "AGI - Construction" },
                        { id: 288, name: "AGI - Environmental" },
                        { id: 289, name: "AGI - Operations and Maintenance" },
                        { id: 295, name: "AGI - Support" },]
                },
                {
                    id: 166, name: "Joint Force Headquarters", children: [
                        { id: 261, name: "Army Band" },
                        { id: 262, name: "Biak Training Center" },
                        { id: 263, name: "Camp Rilea" },
                        { id: 264, name: "Civil Support Team" },
                        { id: 324, name: "Honor Guard" },
                        { id: 265, name: "Medical Command" },
                        { id: 266, name: "Museum" },
                        { id: 270, name: "Regional Training Institute" },
                        {
                            id: 271, name: "State Aviation Office", children: [
                                { id: 272, name: "Pendleton AASF" },
                                { id: 273, name: "Salem AASF" },
                            ]
                        },
                        {
                            id: 274, name: "Surface Maintenance Shops", children: [
                                { id: 313, name: "Clackamas FMS" },
                                { id: 275, name: "COUTES" },
                                { id: 276, name: "CRUTES" },
                                { id: 309, name: "CSMS" },
                                { id: 308, name: "Forest Grove FMS" },
                                { id: 277, name: "La Grande FMS" },
                                { id: 315, name: "Maison FMS Portland Subshop" },
                                { id: 278, name: "Medford FMS" },
                                { id: 279, name: "Portland FMS" },
                                { id: 280, name: "Salem FMS" },
                                { id: 281, name: "Springfield FMS" },
                                { id: 314, name: "Umatilla FMS" },
                            ]
                        },
                        { id: 284, name: "United States Property and Fiscal Office", children: [] },
                    ]
                },
                { id: 301, name: "Office of the Adjutant General", children: [{ id: 302, name: "Public Affairs Office" }] },                
                { id: 316, name: "Oregon Training Command" },
                {
                    id: 303, name: "Oregon Youth Challenge Program", children: [
                        { id: 304, name: "Group Life Coordinators" },
                        { id: 305, name: "Support Staff" }
                    ]
                },



            ]
        },
        {
            id: 400, // does not match legacy DB. All users with org memberships outside of 165 - ORARNG will need this added to their materialization path
            name: "Other",
            children: [                
                { id: 325, name: "ORANG", children: [{ id: 329, name: "116 ACS" }] },
                {
                    id: 326,
                    name: "USAR",
                    children: [
                        {
                            id: 330, name: "364 CA", children: [
                                { id: 331, name: "D Co 448 CA" },
                            ]
                        },
                        { id: 332, name: "671 EN" },
                        { id: 333, name: "909th" },
                    ]
                },
                { id: 327, name: "USMC" },
                { id: 328, name: "USN" },   
            ]
        },        
    ];