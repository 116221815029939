import { UserProfile } from "../auth/authAPI";
import { Compulsion, UserCourseCompulsion } from "./data/userCourse";

type CourseCompulsion = { compulsion: Compulsion, compulsionDetail: UserCourseCompulsion[]; };
export default function getUserCourseRequirement(courseUid: string, userProfile?: UserProfile): CourseCompulsion {
    let rule: CourseCompulsion = {
        compulsion: "Optional",
        compulsionDetail: []
    };
    if (userProfile) {
        let upp = {
            isEPOC: userProfile["dc024ff8-7046-422f-8628-fcd7b247a72e"] === "1",
            isShopEPOC: userProfile["f2cc7462-c956-41ad-bab6-1e3bb6c84f43"] === "1",
            isEPOCSupervisor: userProfile["5744bda3-9db6-4dbd-b2dc-a876b6c46dd4"] === "1",
            handlesHW: userProfile["482425c2-1394-4788-ba9a-ef341c8886f3"] === "1",
            isCSMSSectionEPOC: userProfile["29e85428-6d03-4d9f-b07e-8255fc462232"] === "1",
            atCampRilea:
                Array.isArray(userProfile["FFA9B940-99C4-4A04-BE6E-3F0C5AACEB6E"]) &&
                userProfile["FFA9B940-99C4-4A04-BE6E-3F0C5AACEB6E"].find(a => a === 48) !== undefined
        };

        switch (courseUid) {
            case "CC41734B-4B07-4F3C-AE6B-F2B01AC2B809":
                // EPOC - Instructor Led Training
                //if (userProfile["dc024ff8-7046-422f-8628-fcd7b247a72e"] === "1") 
                if (upp.isEPOC)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Are you an EPOC?",
                        userProfileFieldValue: "Yes"
                    });
                if (upp.isShopEPOC)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Do you work at a maintenance shop?",
                        userProfileFieldValue: "Yes"
                    });
                if (upp.isEPOCSupervisor)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Are you a supervisor of an EPOC?",
                        userProfileFieldValue: "Yes"
                    });
                break;

            case "6BC60C29-2B31-494C-8002-F8564EB46B1D":
                // EPOC - Refresher Training
                if (upp.isEPOC)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Are you an EPOC?",
                        userProfileFieldValue: "Yes"
                    });
                if (upp.isShopEPOC)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Do you work at a maintenance shop?",
                        userProfileFieldValue: "Yes"
                    });
                if (upp.isEPOCSupervisor)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Are you a supervisor of an EPOC?",
                        userProfileFieldValue: "Yes"
                    });
                break;
            case "9998B7D8-A367-4703-9E26-E2D1D544B378":
                // EPOC - Online Training - Part 1
                if (upp.isEPOC)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Are you an EPOC?",
                        userProfileFieldValue: "Yes"
                    });
                if (upp.isShopEPOC)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Do you work at a maintenance shop?",
                        userProfileFieldValue: "Yes"
                    });
                if (upp.isEPOCSupervisor)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Are you a supervisor of an EPOC?",
                        userProfileFieldValue: "Yes"
                    });

                break;
            case "0AF80753-BBAD-457F-8A41-B8D4933F3EC2":
                // "EPOC - Online Training - Part 2
                if (upp.isEPOC)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Are you an EPOC?",
                        userProfileFieldValue: "Yes"
                    });
                if (upp.isShopEPOC)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Do you work at a maintenance shop?",
                        userProfileFieldValue: "Yes"
                    });
                if (upp.isEPOCSupervisor)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Are you a supervisor of an EPOC?",
                        userProfileFieldValue: "Yes"
                    });
                break;
            case "858A6F0B-A023-4876-9F6B-43CCB05E13F0":
                // Resource Conservation and Recovery Act (RCRA) Training
                if (upp.isShopEPOC)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Do you work at a maintenance shop?",
                        userProfileFieldValue: "Yes"
                    });
                if (upp.handlesHW)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Do you move hazardous waste from  one location to another at an LQG/SQG site?",
                        userProfileFieldValue: "Yes"
                    });
                break;
            case "62816578-266D-488A-B15F-1A344812F210":
                // Camp Rilea HW Training
                if (upp.isShopEPOC)
                    rule.compulsionDetail.push({
                        compulsion: upp.atCampRilea ? "Required" : "Recommended",
                        userProfileField: upp.atCampRilea ? "Do you work at a maintenance shop? & Facility" : "Do you work at a maintenance shop?",
                        userProfileFieldValue: upp.atCampRilea ? "Yes & Camp Rilea" : "Yes"
                    });
                if (upp.handlesHW)
                    rule.compulsionDetail.push({
                        compulsion: upp.atCampRilea ? "Required" : "Recommended",
                        userProfileField: upp.atCampRilea ? "Do you move hazardous waste from  one location to another at an LQG/SQG site? & Facility" : "Do you move hazardous waste from  one location to another at an LQG/SQG site?",
                        userProfileFieldValue: upp.atCampRilea ? "Yes & Camp Rilea" : "Yes"
                    });
                break;
            case "CCE558FA-866B-4319-94D1-532AD9A5992F":
                // Hazardous Waste Online Training
                if (upp.isShopEPOC)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Do you work at a maintenance shop?",
                        userProfileFieldValue: "Yes"
                    });
                if (upp.handlesHW)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Do you move hazardous waste from  one location to another at an LQG/SQG site?",
                        userProfileFieldValue: "Yes"
                    });
                if (upp.isCSMSSectionEPOC)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Are you a CSMS Section EPOC?",
                        userProfileFieldValue: "Yes"
                    });
                break;
            case "7ED3AE12-4AD6-463D-BD77-CEA8CA7BF635":
                // CSMS Section EPOC Training
                if (upp.isShopEPOC)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Do you work at a maintenance shop?",
                        userProfileFieldValue: "Yes"
                    });
                if (upp.isCSMSSectionEPOC)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Are you a CSMS Section EPOC?",
                        userProfileFieldValue: "Yes"
                    });
                if (upp.handlesHW)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Do you move hazardous waste from  one location to another at an LQG/SQG site?",
                        userProfileFieldValue: "Yes"
                    });
                break;
            case "87A80F41-7471-4301-AA3B-4C87305C9518":
                // Environmental Compliance Training for Supervisors 
                if (upp.isEPOCSupervisor)
                    rule.compulsionDetail.push({
                        compulsion: "Required",
                        userProfileField: "Are you a supervisor of an EPOC?",
                        userProfileFieldValue: "Yes"
                    });
                break;
        }
        if (rule.compulsionDetail.filter(f => f.compulsion === "Recommended").length > 0)
            rule.compulsion = "Recommended";
        if (rule.compulsionDetail.filter(f => f.compulsion === "Required").length > 0)
            rule.compulsion = "Required";
    }
    return rule;
}